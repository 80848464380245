<template>
  <v-container>
    <v-row class="py-lg-16 py-5">
      <v-col
        class="d-flex align-center justify-center pr-lg-0 pa-0"
        cols="12"
        lg="auto"
      >
        <h3
          class="
            text-lg-h3 text-h4 text-center text-lg-left text-green
            font-weight-black
            text-uppercase
          "
        >
          {{ title }}
        </h3>
      </v-col>
      <v-col class="px-10 px-lg-0 d-none d-lg-flex" cols="12" lg="auto">
        <v-img
          src="/assets/linea1.svg"
          class="mt-n6 mx-auto"
          min-width="300"
          max-width="700"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "title1",
  props: ["title"],
};
</script>
