const messages = {
  drawings: "Drawings",
  btnDownload: "Download",
  btnUrl: "Play",
  btnShowMoreDrawings: "Show More Drawings",
  crafts: "Crafts",
  btnShowMoreCrafts: "Show More Crafts",
  btnShowMoreGame: "Show More Games",
  games: "Games",
};
export default messages;
