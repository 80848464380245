const messages = {
  videoGallery: "Video Gallery",
  btnShowNow: "View Now",
  episode: "E",
  season: "S",
  returnTotheAdventureGallery:
    "<b style='font-size:22px'>←</b> Return to the adventure gallery",
  return: "<b style='font-size:22px'>←</b>",
  sypnosis: "Synopsis",
  downloadable: "Downloadable",
  drawing: "Drawing",
  reading: "Reading",
  craft: "Craft",
  comments: "Comments",
  nextEpisode: "Next Episode",
  playVideosAutomatically: "Play Videos <br/>Automatically",
  comment: "Comment",
  name: "Name",
  message: "Message",
  btnSend: "Send",
  commentMessage:
    "Your comment has been sent correctly, you will be able to see it as soon as it is approved. ",
  viewAgain: "View Again",
};
export default messages;
