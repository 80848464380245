import charactersServices from "../../services/characters";

export default {
  state: () => ({
    characters: [],
  }),
  getters: {
    getCharacters: (state) => state.characters,
  },
  actions: {
    async fetchGetCharacters({ commit }) {
      const { data: charactersData } = await charactersServices.getCharacters();
      commit("setCharacters", charactersData.data);
    },
  },
  mutations: {
    setCharacters(state, data) {
      state.characters = data;
    },
  },
  namespaced: true,
};
