const messages = {
  contactUs: "Kontakte nou",
  writteUs:
    "Ekri nou yon mesaj si ou vle konnen plis sou <br class='d-none d-lg-flex' /> Abby's World Project.",
  name: "Non",
  email: "Mail",
  message: "Mesaj",
  btnSend: "Voye",
  followUs: "Swiv Abby sou avantur li yo atravè <b> rezo sosyal </b>.",
  sendMessage: "Yo voye mesaj ou a avèk siksè",
  requiredField: "jaden obligatwa",
  emailValidation: "imel la pa valab",
  charactersMaximum: "60 karaktè maksimòm",
};
export default messages;
