<template>
  <v-app>
    <language-page v-if="!$store.getters.getLang"></language-page>
    <v-main v-else>
      <header-bar />
      <menu-mobile class="d-lg-none" />
      <router-view />
      <footer-view />
    </v-main>
  </v-app>
</template>

<script>
import headerBar from "@/components/header.vue";
import footerView from "@/components/footer.vue";
import languagePage from "@/views/languagePage.vue";
import menuMobile from "@/components/menuMobile.vue";
import { mapGetters } from "vuex";
import axios from "./services/axios";
import login from "./services/login";
import { auth } from "./services/firebase";
import { signInAnonymously } from "firebase/auth";

export default {
  name: "App",
  components: {
    headerBar,
    footerView,
    languagePage,
    menuMobile,
  },
  created() {
    axios.get("/sanctum/csrf-cookie").then( async () => {
      const singIn = await signInAnonymously(auth);
      localStorage.setItem('anonymousId', singIn.user.uid);
      login.login();
    });
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
};
</script>
<style>
@import "/styles/app.scss";
@import url("/styles/variables.scss");
</style>
