<template>
  <v-container
    fluid
    class="pa-0 min-height language-page d-flex align-sm-center"
  >
    <v-row class="background-panal">
      <v-container>
        <v-row>
          <v-col>
            <v-img
              src="/assets/logoconabby.png"
              class="mx-auto logoAbby mt-lg-n3"
              max-width="300"
              contain
            />
          </v-col>
        </v-row>
        <v-row class="justify-center py-5">
          <h2 class="text-h4 text-green">¡Elige un <b>idioma!</b></h2>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="8" lg="3">
            <v-select
              class="select-lang"
              v-model="lang"
              :items="getLanguages"
              item-text="name"
              item-value="code"
              solo
              @change="saveLang(lang)"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  name: "languages",
  data() {
    return {
      lang: null,
      languages: ["Español", "Ingles", "Creole"],
    };
  },
  computed: {
    ...mapGetters(["getLanguages"]),
  },
  methods: {
    ...mapMutations(["saveLang"]),
  },
};
</script>
