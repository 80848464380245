const messages = {
  home: "Home",
  aboutUs: "About Us",
  activities: "Activities",
  privacyPolicies: "Privacy Policies",
  videoGallery: "Adventures <br>Gallery",
  contactUs: "Contact Us",
  allRightsReserved: "All rights reserved",
  quickSearch: "Quick search",
  terms: "Terms and Conditions",
};
export default messages;
