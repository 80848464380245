<template>
  <v-container class="pa-lg-0 px-10">
    <title-2 :title="$t('menu.terms')"></title-2>
    <v-container class="mt-lg-n5 mb-lg-16">
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("terms.acceptanceOfConditionsOfUse") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('terms.acceptanceOfConditionsOfUseDescription')"
      ></p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("terms.copyright") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('terms.copyrightDescription')"
      ></p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("terms.licenseAndAccessToWebSite") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('terms.licenseAndAccessToWebSiteDescription')"
      ></p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("terms.disclaimerOfWarranties") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('terms.disclaimerOfWarrantiesDescription')"
      ></p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("terms.commentPanels") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('terms.commentPanelsDescription')"
      ></p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("terms.linkToExternalSites") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('terms.linkToExternalSitesDescription')"
      ></p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("terms.contactInformation") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('terms.contactInformationDescription')"
      ></p>
    </v-container>
  </v-container>
</template>
<script>
import title2 from "../components/titles/title2.vue";

export default {
  name: "terms",
  components: {
    title2,
  },
};
</script>
