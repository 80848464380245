import http from "./axios";

export default {
  async getCharacters() {
    try {
      return await http.get(`/api/characters`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};
