import Vue from "vue";
import Vuex from "vuex";
import episodes from "./modules/episodes";
import seasons from "./modules/seasons";
import activities from "./modules/activities";
import tags from "./modules/tags";
import characters from "./modules/characters";
import comments from "./modules/comments";
import contactServices from "../services/contactus";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    language: localStorage.getItem("language"),
    languages: [
      {
        name: "Español",
        code: "es",
        flag: "do",
      },
      {
        name: "English",
        code: "en",
        flag: "us",
      },
      {
        name: "Creole",
        code: "fr",
        flag: "ht",
      },
    ],
    contactUs: {
      name: "",
      email: "",
      meesage: "",
    },
  },
  getters: {
    getLang: (state) => state.language,
    getLanguages: (state) => state.languages,
  },
  actions: {
    async sendMessage({ commit }, data) {
      const { data: emailData } = await contactServices.sendEmail(data);
      commit("sendEmailContact", emailData);
    },
  },
  mutations: {
    saveLang(state, data) {
      state.language = data;
      localStorage.setItem("language", data);
    },
    getLanguage(state) {
      state.language = localStorage.getItem("language") || "es";
    },
    sendEmailContact(state, data) {
      state.email = data;
    },
  },
  modules: {
    episodes,
    seasons,
    activities,
    tags,
    characters,
    comments,
  },
});
