const messages = {
  haveYouSeenOurLastEpisode: "Have You Seen<br> <b>Our Last Episode?<b>",
  btnShowNow: "View Now",
  btnShowNowSpace: "View<br/> Now",
  wantToSeeMoreAbbyAdventures: "Want to see <b>more Abby adventures</b>?",
  seeMoreAdventures: "SEE MORE ADVENTURES",
  recentAdventures: "recent adventures",
  knowTheCharacters: "Know the Characters",
  downloadableActivities: "Downloadable Activities",
  btnShowAll: "View All",
  showMoreActivities: "View more Activities",
  btnKnowMore: "Know More",
  season: "Season",
};
export default messages;
