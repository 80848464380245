<template>
  <v-container class="px-0">
    <v-row class="pt-lg-10">
      <v-col
        class="d-flex align-center justify-center justify-lg-start px-0"
        cols="12"
        lg="auto"
      >
        <h3
          class="
            text-lg-h3 text-h4
            font-weight-black
            text-uppercase text-green text-center text-lg-left
          "
        >
          {{ title }}
        </h3>
      </v-col>
      <v-col class="d-none d-lg-flex" cols="12" lg="auto">
        <v-img
          src="/assets/linea3.svg"
          class="mt-n6"
          min-width="600"
          max-width="630"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "title3",
  props: ["title"],
};
</script>
