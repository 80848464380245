const messages = {
  videoGallery: "Galeri videyo",
  btnShowNow: "Gade kounye a",
  episode: "E",
  season: "S",
  returnTotheAdventureGallery:
    "<b style='font-size:22px'>←</b>Retounen nan Galeri avanti",
  return: "<b style='font-size:22px'>←</b>",
  sypnosis: "Rezime",
  downloadable: "Telechaje",
  drawing: "Li trase",
  reading: "Lekti",
  craft: "Atizana",
  comments: "Kòmantè",
  nextEpisode: "Pwochen chapit",
  playVideosAutomatically: "Jwe Videyo<br/> otomatikman",
  comment: "Kòmantè",
  name: "Non",
  message: "Mesaj",
  btnSend: "Voye",
  commentMessage:
    "Kòmantè ou a te voye kòrèkteman, ou pral wè li le pli vit ke li apwouve.",
  viewAgain: "gade ankò",
};
export default messages;
