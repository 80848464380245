const messages = {
  home: "Kòmanse",
  aboutUs: "Sou nou",
  activities: "Aktivite",
  privacyPolicies: "Règ sou enfòmasyon prive",
  videoGallery: "Galeri <br>Avanti",
  contactUs: "Kontakte nou",
  allRightsReserved: "Tout dwa rezève",
  quickSearch: "Rechèch rapid",
  terms: "Tèm ak Kondisyon yo",
};
export default messages;
