<template>
  <v-container class="contactUs-container">
    <title-3 :title="$t('contactUs.contactUs')"></title-3>
    <v-row class="justify-center my-10 px-10">
      <p class="text-h5 text-center" v-html="$t('contactUs.writteUs')"></p>
    </v-row>
    <v-row class="px-10 px-lg-3">
      <v-form ref="form" class="form-contact">
        <v-row>
          <v-col cols="12" lg="6">
            <v-text-field
              :label="$t('contactUs.name')"
              filled
              rounded
              dense
              v-model="contactus.name"
              :rules="validationRules.nameRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="6">
            <v-text-field
              :label="$t('contactUs.email')"
              filled
              rounded
              dense
              v-model="contactus.email"
              :rules="validationRules.emailRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" lg="12">
            <v-textarea
              :label="$t('contactUs.message')"
              filled
              rounded
              dense
              v-model="contactus.message"
              :rules="validationRules.messageRules"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-btn class="py-6 px-8 first-btn" @click="sendNotifications()">
            {{ $t("contactUs.btnSend") }}
          </v-btn>
        </v-row>
      </v-form>
    </v-row>
    <v-row class="justify-center py-16 px-10">
      <p class="text-h5 text-center" v-html="$t('contactUs.followUs')"></p>
    </v-row>
    <v-row class="justify-center pb-16 mb-10">
      <v-col cols="12" lg="3">
        <v-row class="justify-center">
          <v-btn
            min-width="50"
            height="50"
            class="btn-redes rounded-circle"
            href="https://www.youtube.com/channel/UCu8lJ429Lyresbwixozt_QA/videos"
            target="_blank"
          >
            <v-img src="/assets/redes/yb.svg" max-width="35" class="pa-0 ma-0"
          /></v-btn> </v-row
        ><v-row class="justify-center">
          <a
            href="https://www.youtube.com/channel/UCu8lJ429Lyresbwixozt_QA/videos"
            target="_blank"
            class="text-h6 font-weight-bold ma-0"
            >@elmundodeabby</a
          >
        </v-row></v-col
      >
      <v-col cols="12" lg="3" class="py-10 py-lg-0">
        <v-row class="justify-center">
          <v-btn
            min-width="50"
            height="50"
            class="btn-redes rounded-circle"
            href="https://www.instagram.com/elmundodeabbyrd/"
            target="_blank"
          >
            <v-img src="/assets/redes/ig.svg" max-width="30" class="pa-0 ma-0"
          /></v-btn> </v-row
        ><v-row class="justify-center pt-lg-2">
          <a
            href="https://www.instagram.com/elmundodeabbyrd/"
            target="_blank"
            class="text-h6 font-weight-bold ma-0"
            >@elmundodeabbyrd</a
          >
        </v-row></v-col
      >
      <v-col cols="12" lg="3">
        <v-row class="justify-center">
          <v-btn
            min-width="50"
            height="50"
            class="btn-redes rounded-circle"
            href="https://www.facebook.com/elmundodeabby.abby.9"
            target="_blank"
            ><v-img src="/assets/redes/fb.svg" max-width="20" class="pa-0 ma-0"
          /></v-btn> </v-row
        ><v-row class="justify-center align-center">
          <a
            href="https://www.facebook.com/elmundodeabby.abby.9"
            target="_blank"
            class="text-h6 font-weight-bold ma-0"
            >@elmundodeabby</a
          >
        </v-row></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import title3 from "../components/titles/title3.vue";
import { mapActions } from "vuex";
import Swal from "sweetalert2";
export default {
  components: { title3 },
  name: "contactUs",
  data() {
    return {
      contactus: {
        name: "",
        email: "",
        message: "",
      },
    };
  },
  computed: {
    validationRules() {
      return {
        nameRules: [
          (v) => !!v || this.$t("contactUs.requiredField"),
          (v) => (v && v.length <= 60) || this.$t("contactUs.charactersMaximum"), 
        ],
        emailRules: [
          (v) => !!v || this.$t("contactUs.requiredField"),
          (v) => /.+@.+\..+/.test(v) || this.$t("contactUs.emailValidation"),
        ],
        messageRules: [(v) => !!v || this.$t("contactUs.requiredField")],
      };
    },
  },
  methods: {
    ...mapActions(["sendMessage"]),
    sendNotifications() {
      if (this.$refs.form.validate()) {
        this.sendMessage(this.contactus);
        Swal.fire({
          title: this.$t("contactUs.sendMessage"),
        });
        this.contactus.name = "";
        this.contactus.email = "";
        this.contactus.message = "";
      }
    },
  },
};
</script>
