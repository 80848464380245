<template>
  <v-container class="header-bar py-lg-16 py-5 px-sm-16 px-lg-0" id="header">
    <v-row>
      <v-col cols="4" class="d-lg-none d-flex flex-column justify-center">
        <router-link to="/adventures-gallery">
          <v-img
            src="/assets/header/colmena.png"
            class="colmena mx-auto mt-7 mb-sm-n5"
          />
        </router-link>
        <v-row>
          <v-img
            src="/assets/header/burbuja.svg"
            width="110"
            min-width="110"
            contain
            class="burbuja d-flex align-center mt-2 ml-n2 mt-sm-0"
          >
            <v-container class="mt-4">
              <p
                v-html="$t('menu.videoGallery')"
                class="text-header text-center font-weight-bold mt-1"
              ></p>
            </v-container>
          </v-img>
        </v-row>
      </v-col>
      <v-col cols="4" lg="4">
        <a href="/">
          <v-img
            src="/assets/header/logo.png"
            class="ml-lg-n10 logo mx-auto"
            contain
        /></a>
      </v-col>
      <v-col cols="4" class="d-lg-none d-flex justify-sm-end pr-sm-16">
        <v-card class="stickflag ml-10 mt-10 mt-sm-16 mr-md-16">
          <v-row class="justify-center">
            <v-select
              v-model="lang"
              :items="getLanguages"
              item-value="code"
              class="mx-auto mt-n12 ml-n2"
            >
              <template slot="selection" slot-scope="{ item }">
                <span class="flag_container ml-n4">
                  <country-flag :country="item.flag" class="ml-n2" />
                  <p
                    class="
                      ml-10
                      mt-n9
                      mr-3
                      text-uppercase
                      font-weight-bold
                      no-wrap
                    "
                    v-html="certifiedLang(item.code)"
                  ></p
                ></span>
              </template>
              <template slot="item" slot-scope="{ item }">
                <span class="flag_container_list" @click="saveLang(item.code)">
                  <country-flag :country="item.flag"
                /></span>
              </template>
            </v-select>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" lg="8" class="justify-center d-lg-flex d-none">
        <v-row class="px-8 justify-center absolute mr-n16 ml-5">
          <v-col v-for="(item, index) in items" :key="index">
            <v-card class="card-header mt-10" :to="item.to">
              <v-row justify="center">
                <v-img
                  :src="item.icon"
                  width="52"
                  height="70"
                  contain
                  class="mx-auto mt-n10 icon-menu"
                />
              </v-row>
              <v-row justify="center">
                <v-card-title
                  v-text="$t(item.title)"
                  class="text-header text-center font-weight-bold"
                ></v-card-title>
              </v-row>
            </v-card>
          </v-col>
          <v-col class="colmena-parent">
            <router-link to="/adventures-gallery">
              <v-img
                src="/assets/header/colmena.png"
                width="110"
                class="mb-10 mt-n8 colmena"
              />
            </router-link>
            <v-img
              src="/assets/header/burbuja.svg"
              width="140"
              contain
              class="burbuja"
            >
              <v-container class="ma-0">
                <p
                  v-html="$t('menu.videoGallery')"
                  class="text-header text-center font-weight-bold mt-1"
                ></p>
              </v-container>
            </v-img>
          </v-col>
          <v-col>
            <v-card class="stickflag ml-10 mt-7">
              <v-row justify="center">
                <v-select
                  v-model="lang"
                  :items="getLanguages"
                  item-value="code"
                  class="mx-auto mt-n12 ml-n2"
                >
                  <template slot="selection" slot-scope="{ item }">
                    <span class="flag_container ml-n3">
                      <country-flag :country="item.flag" />
                      <p
                        class="ml-12 mt-n9 mr-4 text-uppercase font-weight-bold"
                        v-html="certifiedLang(item.code)"
                      ></p
                    ></span>
                  </template>
                  <template slot="item" slot-scope="{ item }">
                    <span
                      class="flag_container_list"
                      @click="saveLangAndUpdateURL(item.code)"
                    >
                      <country-flag :country="item.flag"
                    /></span>
                  </template>
                </v-select>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="absolute grama-menu mr-n16 ml-5">
          <v-img src="/assets/header/grama.png" contain width="750" />
        </v-row>
      </v-col>
    </v-row>
    <v-row class="grama-menu d-lg-none mt-n14 mt-sm-n16">
      <v-img src="/assets/header/grama.png" class="mx-auto" />
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";

export default {
  name: "headerBar",
  components: {
    CountryFlag,
  },
  data() {
    return {
      lang: localStorage.getItem("language"),
      drawer: false,
      group: null,
      items: [
        {
          title: "menu.home",
          icon: "/assets/header/home.svg",
          to: {
            name: "Home",
          },
        },
        {
          title: "menu.aboutUs",
          icon: "/assets/header/aboutus.svg",
          to: {
            name: "AboutUs",
          },
        },
        {
          title: "menu.activities",
          icon: "/assets/header/actividades.svg",
          to: {
            name: "ActivitiesGallery",
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getLanguages", "getLang"]),
  },
  methods: {
    ...mapMutations(["saveLang"]),
    certifiedLang(code) {
      if (code === "fr") {
        code = "ht";
        return code;
      } else {
        return code;
      }
    },
    saveLangAndUpdateURL(lang) {
      this.saveLang(lang);
      if (this.$route.name === "MediaPlayer") {
        this.$router.push("/adventures-gallery");
      }
      this.$router.go();
    },
  },
  watch: {
    inmediate: true,
    group() {
      this.drawer = false;
    },
  },
};
</script>
