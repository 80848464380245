<template>
  <v-container class="px-0">
    <v-row class="pt-lg-16 ma-0 mx-n3">
      <v-col
        class="d-flex align-center justify-center justify-lg-start"
        cols="12"
        lg="auto"
      >
        <h3
          class="
            text-lg-h3 text-h4 text-center text-lg-left text-green
            font-weight-black
            text-uppercase
          "
        >
          {{ title }}
        </h3>
      </v-col>
      <v-col class="d-none d-lg-flex" cols="12" lg="auto">
        <v-img
          src="assets/linea2.svg"
          class="mt-n6 mx-auto mx-lg-0"
          min-width="300"
          max-width="660"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "title2",
  props: ["title"],
};
</script>
