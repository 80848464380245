<template>
  <v-container>
    <div
      class="
        searchFilter
        py-9
        pl-lg-3
        pr-lg-4 pr-5
        d-lg-flex
        flex-column
        mb-10 mb-lg-0
        d-none
      "
    >
      <v-row class="justify-center justify-lg-start mx-auto">
        <h4
          class="
            ml-lg-2
            text-lg-h5 text-h5
            px-4 px-lg-0
            text-white text-center
            font-weight-bold
          "
        >
          {{ $t("menu.quickSearch") }}
        </h4>
      </v-row>
      <v-row class="pb-2 d-none d-lg-flex overflow mb-5">
        <v-col
          v-for="(tag, index) in getTags"
          :key="index"
          class="d-flex justify-center"
        >
          <v-btn
            :style="[
              tag.status
                ? { background: tag.color }
                : { background: colorDesabled },
            ]"
            @click.stop="changeStatus(tag)"
            class="btn-tag"
          >
            <v-row class="justify-space-between">
              <v-col
                cols="8"
                class="
                  text-white
                  font-weight-bold
                  text-button
                  mx-0
                  px-0
                  align-center
                  d-flex
                  justify-center
                "
              >
                {{ tag.name }}
              </v-col>
              <v-col cols="3" class="d-flex align-center mx-0 px-0">
                <v-icon v-if="tag.status" class="icon-btn rounded-circle">
                  mdi-minus
                </v-icon>
                <v-icon v-else class="icon-btn rounded-circle">
                  mdi-plus
                </v-icon>
              </v-col>
            </v-row>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div class="d-lg-none pt-7 mt-n16 justify-end d-flex">
      <v-btn
        class="rounded-circle pa-0"
        dark
        @click.stop="overlay = !overlay"
        min-width="20"
        width="50"
        height="50"
      >
        <v-img src="/assets/lupa.svg" max-width="30" />
      </v-btn>
      <v-overlay :value="overlay" class="search-overlay">
        <v-container>
          <v-row class="mb-n3 justify-center justify-lg-start">
            <h4
              class="
                text-lg-h4 text-h5
                px-4 px-lg-0
                text-white text-center
                font-weight-bold
              "
            >
              {{ $t("menu.quickSearch") }}
            </h4>
          </v-row>
          <v-row class="py-2 overflow mb-5">
            <v-col
              v-for="(tag, index) in getTags"
              :key="index"
              cols="12"
              sm="6"
              class="d-flex justify-center"
            >
              <v-btn
                :style="[
                  tag.status
                    ? { background: tag.color }
                    : { background: colorDesabled },
                ]"
                @click.stop="changeStatus(tag)"
                class="btn-tag"
              >
                <v-row class="justify-space-between">
                  <v-col
                    cols="8"
                    class="
                      text-white
                      font-weight-bold
                      text-button
                      mx-0
                      px-0
                      align-center
                      d-flex
                      justify-center
                    "
                  >
                    {{ tag.name }}
                  </v-col>
                  <v-col cols="3" class="d-flex align-center mx-0 px-0">
                    <v-icon v-if="tag.status" class="icon-btn rounded-circle">
                      mdi-minus
                    </v-icon>
                    <v-icon v-else class="icon-btn rounded-circle">
                      mdi-plus
                    </v-icon>
                  </v-col>
                </v-row>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-overlay>
    </div>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { debounce } from "debounce";

export default {
  name: "searchFilter",
  created() {
    this.fetchGetTags();
    document.addEventListener("click", () => {
      this.overlay = false;
    });
  },
  data() {
    return {
      colorDesabled: "#cfd0d0 !important",
      absolute: true,
      overlay: false,
      debounceQuantity: null,
    };
  },
  computed: {
    ...mapGetters("episodes", ["getEpisodes"]),
    ...mapGetters("tags", ["getTags", "getTagsActive"]),
  },
  methods: {
    ...mapActions("tags", ["fetchGetTags"]),
    changeStatus(tag) {
      if (tag.status) {
        tag.status = false;
      } else {
        tag.status = true;
      }
      if (this.debounceQuantity) {
        this.debounceQuantity.clear();
      }
      this.debounceQuantity = debounce(async () => {
        this.overlay = false;
      }, 2000);
      this.debounceQuantity();
    },
  },
};
</script>
