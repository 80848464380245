import episodesServices from "../../services/episodes";

const episodes = {
  state: () => ({
    episodes: [],
    episode: null,
    like: false,
  }),
  getters: {
    getEpisodes: (state) => state.episodes,
    getEpisode: (state) => state.episode,
    getLike: (state) => state.like,
  },
  actions: {
    async fetchGetEpisodes({ commit }) {
      const { data: episodesData } = await episodesServices.getEpisodes();
      commit("setEpisodes", episodesData.data);
    },
    async fetchGetEpisode({ commit }, id) {
      const { data: episodeData } = await episodesServices.getEpisodeById(id);
      commit("setEpisode", episodeData.data);
    },
    async setLike({ commit }, data) {
      const { data: likeData } = await episodesServices.like(data);
      commit("setLike", likeData);
    },
  },
  mutations: {
    setEpisodes(state, data) {
      state.episodes = data;
    },
    setEpisode(state, data) {
      state.episode = data;
    },
    setLike(state, data) {
      state.like = data;
    },
  },
  namespaced: true,
};

export default episodes;
