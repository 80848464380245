const messages = {
  haveYouSeenOurLastEpisode: "OU VLE WÈ PI <b>Dènye <br>Avanti NOU</b>?",
  btnShowNow: "Gade kounye a",
  btnShowNowSpace: "Gade<br/> kounye a",
  wantToSeeMoreAbbyAdventures: "Vle wè plis <b> plis avantur </b> nan Abby?",
  seeMoreAdventures: "Gade plis avantur",
  recentAdventures: "Dènye Adventures",
  knowTheCharacters: "Men pesonaj yo",
  downloadableActivities: "Aktivite ki ka telechaje",
  btnShowAll: "Gade tout",
  showMoreActivities: "Gade plis aktivite",
  btnKnowMore: "Aprann plis",
  season: "Sezon",
};
export default messages;
