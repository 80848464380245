const messages = {
  drawings: "Dibujos",
  btnDownload: "Descargar",
  btnUrl: "Jugar",
  btnShowMoreDrawings: "Ver más dibujos",
  crafts: "Manualidades",
  btnShowMoreCrafts: "Ver más manualidades",
  btnShowMoreGame: "Ver más Juegos",
  games: "Juegos",
};
export default messages;
