<template>
  <v-container fluid class="preview-activities-container">
    <v-row class="img-grama mt-n14"> </v-row>
    <title-3 :title="$t('home.downloadableActivities')"></title-3>
    <v-container fluid class="py-16 px-10 px-lg-0">
      <v-row class="background-panal mx-0">
        <v-container class="pa-0">
          <v-row class="justify-center justify-lg-start">
            <v-col
              v-for="(item, index) in activities"
              :key="index"
              cols="12"
              :sm="num === 3 ? 6 : 'auto'"
              :md="num === 3 ? 4 : 'auto'"
              lg="auto"
              class="pr-lg-5 d-flex justify-center"
            >
              <v-card
                class="card-activities2 pa-0 ma-lg-0 mx-auto"
                v-if="validateActivity(item.type) && able()"
                :to="'/activities-gallery' + item.hash"
              >
                <v-row class="ma-0">
                  <v-col cols="7" class="pa-0">
                    <v-img :src="item.src"> </v-img>
                  </v-col>
                  <v-col cols="5" class="d-flex flex-column align-center">
                    <v-row>
                      <v-card-title
                        class="text-white text-h4 text-center mx-auto"
                        >{{ item.name }}</v-card-title
                      >

                      <v-btn
                        class="
                          btn-green
                          px-5
                          text-h5
                          font-weight-black
                          ml-auto
                          mt-auto
                        "
                        :to="'/activities-gallery' + item.hash"
                      >
                        {{ $t("home.btnShowAll") }}
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>
              <v-card
                class="card-activities pa-0 ma-lg-0 mx-auto my-5"
                v-if="validateActivity(item.type) && !able()"
                :to="'/activities-gallery' + item.hash"
              >
                <v-img :src="item.src" height="210"> </v-img>
                <v-card-title class="text-white text-h5">{{
                  item.name
                }}</v-card-title>
                <v-card-actions class="justify-end mt-n3 mx-0 pb-0">
                  <v-btn
                    class="btn-green px-5 mr-n2 text-h5 font-weight-black"
                    :to="'/activities-gallery' + item.hash"
                  >
                    {{ $t("home.btnShowAll") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="justify-center px-10">
            <v-btn
              class="second-btn px-10 py-6 mt-16 mx-auto"
              to="/activities-gallery"
            >
              {{ $t("home.showMoreActivities") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import Title3 from "../titles/title3.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { Title3 },
  name: "previewActivities",
  data() {
    return {
      activities: [
        {
          src: "/assets/dibujo.jpeg",
          name: this.$t("activities.drawings"),
          type: "drawings",
          hash: "#drawings",
        },
        {
          src: "/assets/game.png",
          name: this.$t("activities.games"),
          type: "games",
          hash: "#games",
        },
        {
          src: "/assets/manualidad.jpeg",
          name: this.$t("activities.crafts"),
          type: "crafts",
          hash: "#crafts",
        },
      ],
      numActivity: 0,
      drawings: [],
      games: [],
      crafts: [],
    };
  },
  created() {
    this.fetchGetActivities();
  },
  computed: {
    ...mapGetters("activities", ["getActivities"]),
    num: {
      get: function () {
        return this.validateAllType();
      },
      set: function (val) {
        this.numActivity = val;
      },
    },
  },
  methods: {
    ...mapActions("activities", ["fetchGetActivities"]),
    validateActivity(type) {
      if (this.getActivities.map((activity) => activity.type).includes(type)) {
        return true;
      } else {
        return false;
      }
    },

    validateAllType() {
      var nume = 0;
      if (
        this.getActivities.map((activity) => activity.type).includes("drawings")
      ) {
        nume += 1;
      }
      if (
        this.getActivities.map((activity) => activity.type).includes("crafts")
      ) {
        nume += 1;
      }
      if (
        this.getActivities.map((activity) => activity.type).includes("games")
      ) {
        nume += 1;
      }
      return nume;
    },
    able() {
      if (this.num < 3 && window.innerWidth > 1264) {
        return true;
      }
    },
  },
  watch: {
    getActivities(val) {
      this.drawings = val.filter((drawing) => drawing.type === "drawings");
      this.games = val.filter((game) => game.type === "games");
      this.crafts = val.filter((craft) => craft.type === "crafts");
    },
    drawings(val) {
      let num = this.drawings.length - 1;
      if (this.drawings.length > 0) {
        this.activities[0].src = val[num].uploads.find(
          (upload) => upload.main === false
        ).path;
      }
    },
    games(val) {
      let num = this.games.length - 1;
      if (this.games.length > 0) {
        this.activities[1].src = val[num].uploads.find(
          (upload) => upload.main === false
        ).path;
      }
    },
    crafts(val) {
      let num = this.crafts.length - 1;
      if (this.crafts.length > 0) {
        this.activities[2].src = val[num].uploads.find(
          (upload) => upload.main === false
        ).path;
      }
    },
  },
};
</script>
