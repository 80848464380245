<template>
  <v-container class="video-gallery-container">
    <title-1 :title="$t('videoGallery.videoGallery')"></title-1>
    <v-row class="mb-16">
      <v-col cols="12" lg="2">
        <search-filter></search-filter>
      </v-col>
      <v-col cols="12" lg="10">
        <v-row class="mx-lg-3">
          <v-tabs class="seasons-bar" show-arrows hide-slider v-if="seasons">
            <v-tab
              v-for="(season, index) in seasons"
              :key="index"
              @click="changeSeason(season)"
              class="
                text-lg-h5 text-h6
                font-weight-black
                text-center
                mx-sm-10 mx-lg-0
              "
              >{{ $t("home.season") }} {{ season.number }}</v-tab
            >
          </v-tabs>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            lg="4"
            v-for="(episode, index) in episodes"
            class="my-10 justify-center d-flex justify-lg-start flex-column align-center"
            :key="index"
            
          ><a class="episode-link " :href="'aventures-gallery/' + episode.slug[lang]" >
            <v-img
              :src="
                episode.url[lang]
                  ? 'https://img.youtube.com/vi/' +
                    getVideoId(episode.url[lang]).id +
                    '/0.jpg'
                  : '/assets/nofound.jpg'
              "
              class="recent-aventures align-end"
           
            >  
              <v-container>
              
                <v-row class="justify-end">
                  <v-col
                    cols="3"
                    lg="3"
                    class="background-green px-5 d-flex align-center py-0"
                  >
                    <h5
                      class="
                        text-h6 text-center text-white
                        font-weight-bold
                        ml-n1
                      "
                    >
                      {{ $t("videoGallery.season") }}{{ season }}:{{
                        $t("videoGallery.episode")
                      }}{{ episode.number }}
                    </h5>
                  </v-col>
                  <v-col
                    cols="3"
                    lg="4"
                    class="background-yellow d-flex justify-center align-center"
                  >
                    <v-btn
                      class="
                        text-body-2
                        transparent
                        elevation-0
                        text-center
                        font-weight-black
                        show-now
                      "
                      light
                      plain
                      :disabled="!episode.url[lang]"
                      :to="'aventures-gallery/' + episode.slug[lang]"
                      v-html="$t('videoGallery.btnShowNow')"
                    >
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-img></a>
            <v-card class="card-episode d-flex justify-center align-end">
              <v-card-title
                class="text-center text-white font-weight-black text-h6"
                >{{ episode.title[lang] }}</v-card-title
              >
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import searchFilter from "@/components/searchFilter.vue";
import Title1 from "../components/titles/title1.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "videoGallery",
  components: {
    searchFilter,
    Title1,
  },
  created() {
    this.fetchGetSeasons();
    this.episodes = this.getEpisodes;
  },
  data() {
    return {
      seasons: [],
      season: "",
      episodesList: [],
      lang: localStorage.getItem("language"),
    };
  },
  computed: {
    episodes: {
      get: function () {
        return this.getEpisodesByTags();
      },
      set: function (val) {
        this.episodesList = val;
      },
    },
    ...mapGetters("seasons", ["getSeasons"]),
    ...mapGetters("episodes", ["getEpisodes"]),
    ...mapGetters("tags", ["getTagsActive"]),
  },
  methods: {
    getEpisodesByTags() {
      if (this.getTagsActive.length === 0) {
        return this.episodesList;
      }
      return this.episodesList.filter((episode) =>
        episode.tags?.find((tag) =>
          this.getTagsActive
            .map((activeTag) => activeTag.name)
            .includes(tag.name[this.lang])
        )
      );
    },
    changeSeason(season) {
      this.episodes = season.episodes;
      this.season = season.number;
    },
    ...mapActions("seasons", ["fetchGetSeasons"]),
    ...mapActions("episodes", ["fetchGetEpisodes"]),
  },
  watch: {
    getSeasons(val) {
      this.seasons = val
        .filter((season) => season.status)
        .sort((a, b) => (a.number < b.number ? 1 : -1));
      this.season = this.seasons[0].number;
      this.episodesList = this.seasons[0].episodes;
    },
  },
};
</script>
