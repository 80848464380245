const messages = {
  contactUs: "Contact Us",
  writteUs:
    "Write us a message if you want to know more information about the <br class='d-none d-lg-flex' /> project of Abby's World.",
  name: "Name",
  email: "Email",
  message: "Message",
  btnSend: "Send",
  followUs: "Follow Abby on her adventures through <b> social media </b>.",
  sendMessage: "Your message have been sent successfully",
  requiredField: "Required Field",
  emailValidation: "The email is invalid",
  charactersMaximum: "60 characters maximum",
};
export default messages;
