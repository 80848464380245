<template>
  <v-container class="pa-lg-0 px-10">
    <title-1 :title="$t('menu.privacyPolicies')"></title-1>
    <v-container class="mt-lg-n5 mb-lg-16">
      <p class="text-lg-h6 text-justify text-lg-left">
        {{ $t("privacyPolicies.introduction") }}
      </p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("privacyPolicies.recolectionAndUseOfInformation") }}
      </h4>
      <p
        class="text-lg-h6 text-justify text-lg-left"
        v-html="$t('privacyPolicies.recolectionAndUseOfInformationDescription')"
      ></p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("privacyPolicies.webSites") }}
      </h4>
      <p class="text-lg-h6 text-justify text-lg-left">
        {{ $t("privacyPolicies.webSitesDescription") }}
      </p>
      <h4 class="text-lg-h4 font-weight-bold py-lg-5 text-justify text-lg-left">
        {{ $t("privacyPolicies.changeInThisPrivacyPolicy") }}
      </h4>
      <p class="text-lg-h6 text-justify text-lg-left">
        {{ $t("privacyPolicies.changeInThisPrivacyPolicyDescription") }}
      </p>
    </v-container>
  </v-container>
</template>
<script>
import title1 from "../components/titles/title1.vue";

export default {
  name: "Polities",
  components: {
    title1,
  },
};
</script>
