const messages = {
  videoGallery: "Galería de Videos",
  btnShowNow: "Ver<br/> Ahora",
  episode: "E",
  season: "T",
  returnTotheAdventureGallery:
    "<b style='font-size:22px'>←</b>Regresar a la galería de aventuras",
  return: "<b style='font-size:22px'>←</b>",
  sypnosis: "Sinopsis",
  downloadable: "Descargables",
  drawing: "Dibujo",
  reading: "Lectura",
  craft: "Manualidad",
  comments: "Comentarios",
  nextEpisode: "Siguiente Capítulo",
  playVideosAutomatically: "Reproducir videos<br/> automáticamente",
  comment: "Comenta",
  name: "Nombre",
  message: "Mensaje",
  btnSend: "Enviar",
  commentMessage:
    "Tu comentario se ha enviado correctamente, lo verás en cuanto sea aprobado.",
  viewAgain: "Ver de nuevo",
};
export default messages;
