const messages = {
  acceptanceOfConditionsOfUse: "Aceptación de las condiciones de uso.",
  acceptanceOfConditionsOfUseDescription:
    "Bienvenido a elmundodeabby.tv, propiedad de Compassion International República Dominicana. Los servicios ofrecidos y descritos en este sitio están sujetos a las condiciones de uso contenidas en este documento, por favor lea cuidadosamente. Estos servicios incluyen cualquier URL de la marca El Mundo de Abby, el sitio web elmundodeabby.tv y cualquier otro sitio web, contenido, aplicaciones o juegos que sean ofrecidos o estén relacionados a elmundodeabby.tv.<br><br>Compassion International República Dominicana ofrece elmundodeabby.tv, su contenido, aplicaciones y actividades para la edificación, educación, disfrute y entretenimiento personal, sea de manera individual o grupal. Al acceder a este sitio o hacer uso del contenido de este, usted acepta y está de acuerdo con los términos y condiciones indicadas en este documento. Compassion International República Dominicana se reserva el derecho de modificar estas condiciones de uso en cualquier momento, y cada modificación entra en vigor al momento de ser publicadas en elmundodeabby.tv. El uso continuado del sitio elmundodeabby.tv, su contenido, aplicaciones o actividades, después de cualquier modificación constituye su aceptación y acuerdo, por lo que el usuario queda obligado bajo el acuerdo modificado.",
  copyright: "Derechos de autor",
  copyrightDescription:
    "Todo el contenido incluido en este sitio web, tales como texto, gráficos, logos, fotografías, botones, iconos, imágenes, clips de audio, archivos descargables, software, compilación de datos, es de propiedad exclusiva de Compassion International República Dominicana o sus proveedores de contenido y/o servicios y están sujetas a leyes internacionales de derechos de autor.",
  licenseAndAccessToWebSite: "Licencia y acceso al sitio web",
  licenseAndAccessToWebSiteDescription:
    "Compassion International República Dominicana, le concede licencia limitada, no exclusiva, no transferible y revocable para acceder y hacer uso personal de este sitio web y no permite descargar o modificar total o parcialmente este sitio web, excepto con consentimiento escrito de Compassion International RD. Esta licencia no incluye reventa o uso comercial de este sitio ni su contenido; la recolección y uso de cualquier información, imágenes, contenido, fotografías, videos, o cualquier uso derivado de este sitio o su contendido; la descarga o copia de la información para el beneficio económico de un tercero; o el uso de minería de datos, robots o herramientas de extracción y recolección de datos similares. Este sitio o cualquier porción de este no debe ser reproducida, duplicada, copiada, vendida, revendida o de alguna otra forma explotada para algún propósito comercial, sin el consentimiento expreso y por escrito por parte de Compassion International República Dominicana.",
  disclaimerOfWarranties:
    "Renuncia de Garantías y Limitación de responsabilidad",
  disclaimerOfWarrantiesDescription:
    "Este sitio es proporcionado por Compassion International República Dominicana, “Tal Cual” y “Según Disponibilidad”. Compassion International República Dominicana no hace declaraciones ni garantías de ningún tipo, expresas o implícitas, en cuanto al funcionamiento de este sitio o la información, contenido o materiales incluidos en este sitio, tampoco hace garantías o promesas de resultados específicos por el uso de los materiales y el contenido publicado en elmundodeabby.tv. Usted acepta expresamente que el uso de este sitio es bajo su propio riesgo. Compassion International República Dominicana no garantiza que este sitio, sus servidores o el correo electrónico enviado desde el sitio web elmundodeabby.tv estén libres de virus u otros componentes dañinos. Compassion International República Dominicana no será responsable de ningún daño de ningún tipo que surja del uso de este sitio, incluyendo, pero no limitado a, daños indirectos, incidentales, punitivos y consecuentes.",
  commentPanels: "Paneles de comentarios o mensajes",
  commentPanelsDescription:
    "Dentro del sitio elmundodeabby.tv se ofrece a los usuarios la posibilidad de publicar mensajes y/o comentarios públicos en paneles de comentarios o mensajes, los cuales están abiertos al público. Al publicar un comentario o mensaje en uno de estos paneles dentro del sitio web elmundodeabby.tv, usted reconoce que dichos mensajes o comentarios son de carácter publico y que Compassion International República Dominicana no puede garantizar la seguridad de la información que usted publique a través de estos, por tanto, usted hace estas publicaciones bajo su propio riesgo. Compassion International República Dominicana no es responsable del contenido publicado por usuarios o visitantes de elmundodeabby.tv y se reserva el derecho de restringir o eliminar contenido, por cualquier motivo y en cualquier momento.",
  linkToExternalSites: "Enlaces a sitios externos y redes sociales",
  linkToExternalSitesDescription:
    "Dentro del sitio elmundodeabby.tv se proporcionan enlaces de acceso a sitios externos, ya sean de Compassion International República Dominicana, de Compassion International INC, o los sitios de cualquiera de los socios globales de Compassion International. De igual forma se proporcionan enlaces externos a servicios de redes sociales de El Mundo de Abby, Compassion International República Dominicana o de cualquiera de los socios Globales de Compassion International INC. Cuando usted interactúa con alguno de estos sitios externos, usted debe estar de acuerdo con las políticas de privacidad y condiciones de uso que provea ese sitio externo de manera independiente a las políticas de privacidad y condiciones de uso de elmundodeabby.tv. Compassion International República Dominicana no es responsable ni ofrece garantías, expresas o implícitas, respecto a los servicios ofrecidos por los sitios externos y/o redes sociales. Compassion International República Dominicana no es responsable del contenido o practicas de sitios web externos a elmundodeabby.tv incluso si esto están enlazado a elmundodeabby.tv o son operados por empresas relacionadas con de algún modo a Compassion International República Dominicana.",
  contactInformation: "Información de Contacto",
  contactInformationDescription:
    "Si usted tiene preguntas, comentarios o quejas concernientes a nuestras políticas de privacidad o condiciones de uso, puede ponerse en contacto con nosotros al correo info.rd@compassion.com.",
};
export default messages;
