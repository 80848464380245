const messages = {
  home: "Inicio",
  aboutUs: "Sobre Nosotros",
  activities: "Actividades",
  privacyPolicies: "Políticas de privacidad",
  videoGallery: "Galería de <br> Aventuras",
  contactUs: "Contáctanos",
  allRightsReserved: "Todos los Derechos Reservados",
  quickSearch: "Búsqueda rápida",
  terms: "Términos y Condiciones",
};
export default messages;
