const messages = {
  aboutAbbyWorld: "Apwopo de  ¨El mundo de  Abby¨",
  aboutAbbyWorldDescription:
    "¡Bienveni  nan¨ El mundo de Abby¨!<br><br> El mundo de Abby  se yon aktivite  enteraktif  pou timounn, li baze sou pwogram Compassion International pou devlopman global pou timoun de 3 a 9 ane. el mundo de Abby fokis sou devlopman espirityèl, fizik, sosyal, emosionèl e kongnitif. wes  se yon pèsonaj ki reprezante yon monitris ki la pou li gide ti zanmi li Abby. Yo toulede ap aprann ansanm pawòl BonDye a, istwa biblik e aktivite detoulejou yo pandan yo ap pase yon bon moman.",
  aboutAbbyWorldDescription2:
    "El mundo de Abby genyen kòm objetif edike e bay sipò sou edikasyon sou tem tankou la fami, lanmou pwop, relasyon avek lòt moun, anvirwonman, ijyenn, emosyon e lòt sij. tout tem yo ap devlope avek yon vizion ki santre sou Kris e  vizion biblik ki pemet pwomouvwa  valè, panse ak biennèt fizik timounn yo selon laj yo.",
  aboutCompassion: "Apwopo Compassion",
  aboutCompassionDescription:
    "Compassion International nou se yon ministe kap defann  timoun  e nou rasanble mounn  konpatisan ansanm avèk moun ki nan mal site.Misyon nou se retire timoun yo nan mal site nan non Jezi.Objetif nou se pou timoun yo vin yon adilt responsab,total capital e reisy.",
  sponsorAChild: "patwone yon timoun",
};
export default messages;
