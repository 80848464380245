const messages = {
  haveYouSeenOurLastEpisode: "¿QUIERES VER <br class='d-md-none'>NUESTRA MÁS<br> <b>RECIENTE  AVENTURA</b>?",
  btnShowNow: "Ver Ahora",
  btnShowNowSpace: "ver<br/> Ahora",
  wantToSeeMoreAbbyAdventures: "¿Quieres ver<b> más aventuras</b> de Abby?",
  seeMoreAdventures: "Ver más aventuras",
  recentAdventures: "Aventuras Recientes",
  knowTheCharacters: "Conoce los personajes",
  downloadableActivities: "Actividades Descargables",
  btnShowAll: "Ver todos",
  showMoreActivities: "Ver más actividades",
  btnKnowMore: "Conoce Más",
  season: "Temporada",
};
export default messages;
