<template>
  <v-container fluid class="footer-container" id="footer">
    <v-container class="">
      <v-row class="ma-0 py-8">
        <v-col cols="12" lg="6">
          <v-row class="d-lg-none">
            <img
              src="/assets/footer/compassionLogo.svg"
              width="175"
              class="mx-auto"
            />
          </v-row>
          <v-row class="align-center justify-center justify-lg-start">
            <v-btn
              class="btn-redes rounded-circle mr-3"
              href="https://www.youtube.com/channel/UCu8lJ429Lyresbwixozt_QA/videos"
              target="_blank"
            >
              <v-img
                src="/assets/footer/yb.svg"
                max-width="20"
                class="pa-0 ma-0"
              />
            </v-btn>
            <v-btn
              class="btn-redes rounded-circle"
              href="https://www.instagram.com/elmundodeabbyrd/"
              target="_blank"
            >
              <v-img
                src="/assets/footer/ig.svg"
                max-width="20"
                class="pa-0 ma-0"
              />
            </v-btn>
            <v-btn
              class="btn-redes rounded-circle mx-3"
              href="https://www.facebook.com/elmundodeabby.abby.9"
              target="_blank"
            >
              <v-img
                src="/assets/footer/fb.svg"
                max-width="14"
                class="pa-0 ma-0"
              />
            </v-btn>
            <p class="text-follow font-weight-bold ma-0">@elmundodeabby</p>
          </v-row>
          <v-row class="d-lg-none justify-center mt-5">
            <v-breadcrumbs
              :items="items"
              class="
                text-white
                breadcrumb-footer
                justify-center
                font-weight-regular
                px-0
              "
              large
            ></v-breadcrumbs>
          </v-row>
          <v-row class="py-lg-5 py-3 justify-center justify-lg-start">
            <p class="text-copyright">
              <span class="font-weight-bold">El Mundo de Abby</span> ©{{
                $t("menu.allRightsReserved")
              }}
              2021
            </p>
          </v-row>
          <a href="https://mixart.do/home" target="_blank">
            <v-row class="mt-0 justify-center justify-lg-start">
              <p class="text-mixart">By Mixart</p>
              <v-col cols="auto" class="pa-0 ml-1 my-0">
                <v-img
                  src="/assets/footer/mixart.png"
                  width="10"
                  class="pa-2"
                />
              </v-col>
            </v-row>
          </a>
        </v-col>
        <v-col cols="12" lg="6" class="">
          <v-row class="justify-lg-end d-none d-lg-flex">
            <v-breadcrumbs
              :items="items"
              class="text-white breadcrumb-footer font-weight-regular"
              large
            ></v-breadcrumbs>
          </v-row>
          <v-row class="justify-lg-end d-none d-lg-flex">
            <a href="https://www.compassion.com/" target="_blank">
              <img src="/assets/footer/compassionLogo.svg" width="175" />
            </a>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
export default {
  name: "footerView",
  data() {
    return {
      items: [
        {
          text: this.$t("menu.privacyPolicies"),
          disabled: false,
          href: "/policies",
        },
        {
          text: this.$t("menu.contactUs"),
          disabled: false,
          href: "/contact-us",
        },
        {
          text: this.$t("menu.terms"),
          disabled: false,
          href: "/terms",
        },
      ],
    };
  },
};
</script>
