const messages = {
  introduction:
    "Abby’s World is an interactive program, created by Compassion International Republica Dominicana, based at Calle Ing. David Masalle, No. 38, Urbanización Fernandez, Santo Domingo, D.N. and that he can be contacted through the email info.rd@compassion.com. This program is distributed to users through the website https://elmundodeabby.tv/ and other social media platforms. This document is intended to introduce our privacy policies and practices and inform you of how we collect and use your information through this website.",
  recolectionAndUseOfInformation:
    "Collection, use of information and use of Cookies",
  recolectionAndUseOfInformationDescription:
    "Within the elmundodeabby.tv website, information is collected from users or visitors for the following purposes:<br> <ul><li>To be able to visualize the regions where our website is being used.</li><li>	To improve your experience while using the services of our website.</li><li>To conduct analysis.</li><li>To moderate comments.</li></ul><br>When you access and interact with elmundodeabby.tv from the servers where our website is hosted, information about your visit may be collected. For example: information about your device, browser or the IP address from where you are accessing our website.<br><br> In the process of interacting with the website content, cookies are used to improve the experience of using the website. A Cookie are data characters that, when used on a website, are placed by the web server in the applications folder of your computer's browser. Once on your computer, the cookie will allow the website to 'recognize' you as a unique person. Cookies do not collect data from the end user's device. Cookies are used, for example: when you 'like' the videos.<br><br>Cookies can be removed from your device. Also, depending on the type of web browser and the browser version you are using, you may be able to change the properties of your cookie file so that cookies are not used or saved. Check with your browser provider for more information on deleting cookies.",
  webSites: "Third Party Websites and/or Applications",
  webSitesDescription:
    "Compassion International Dominican Republic has no control over the privacy policies or practices of third-party websites or applications, even if they are linked in some way to the elmundodeabby.tv website, in this sense, users and visitors should consult the privacy policies of these third party sites.",
  changeInThisPrivacyPolicy: "Changes to this Privacy Policy",
  changeInThisPrivacyPolicyDescription:
    "Compassion International Republica Dominicana reserves the right to modify or update this privacy policy to adjust and comply with applicable laws. Users and visitors should check the privacy policy's effective and / or update dates at the top of this document to find out when it was last modified.",
};

export default messages;
