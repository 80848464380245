const messages = {
  drawings: "Desen",
  btnDownload: "Telechaje",
  btnUrl: "Jwe",
  btnShowMoreDrawings: "Gade plis desen",
  crafts: "Atizana",
  btnShowMoreCrafts: "Gade plis atizana",
  btnShowMoreGame: "Gade plis jwèt",
  games: "Jwèt",
};
export default messages;
