import tagsServices from "../../services/tags";

export default {
  state: () => ({
    tags: [],
  }),
  getters: {
    getTags: (state) => state.tags,
    getTagsActive: (state) => state.tags.filter((tag) => tag.status === true),
  },
  actions: {
    async fetchGetTags({ commit }) {
      const { data: tagsData } = await tagsServices.getTags();
      commit("setTags", tagsData.data);
    },
  },
  mutations: {
    setTags(state, data) {
      state.tags = data;
    },
  },
  namespaced: true,
};
