const messages = {
  introduction:
    "El Mundo de Abby es un programa interactivo, creado por Compassion International República Dominicana, con sede en la Calle Ing. David Masalle, No. 38, Urbanización Fernandez, Santo Domingo, D.N. y que puede ser contactado a través del correo info.rd@compassion.com. Este programa es distribuido a los usuarios a través del sitio web https://elmundodeabby.tv/ y otras plataformas de redes sociales. Este documento tiene como objetivo presentar nuestras prácticas y políticas de privacidad e infórmale la forma en que recolectamos y usamos su información a través de este sitio web.",
  recolectionAndUseOfInformation:
    "Recolección, uso de información y uso de Cookies",
  recolectionAndUseOfInformationDescription:
    "Dentro del sitio web elmundodeabby.tv se recolecta información de los usuarios o visitantes para los siguientes fines:<br> <ul><li>Para poder visualizar las regiones donde nuestro sitio web está siendo utilizado. </li><li>	Para mejorar su experiencia mientras usa los servicios de nuestro sitio web. </li><li>	Para conducir análisis. </li><li>	Para moderar comentarios.</li></ul><br>Cuando accede e interactúa con elmundodeabby.tv desde los servidores donde este alojado nuestro sitio web se pueden recolectar informaciones acerca de su visita. Por ejemplo: información sobre su dispositivo, navegador o la dirección IP desde donde este accediendo a nuestro sitio web.<br><br> En el proceso de interacción con el contenido de sitio web, se utilizan cookies para mejorar la experiencia de uso del sitio web. Una Cookie son caracteres de datos que, cuando son utilizados en un sitio web, el servidor web los coloca en la carpeta de aplicaciones del navegador de su computador. Una vez en su computador, la cookie permitirá que el sitio web lo “reconozca” como una persona única. Las cookies no recopilan datos del dispositivo del usuario final. Las cookies son utilizadas, por ejemplo: al momento de dar “me gusta” en los videos.<br><br>Las cookies se pueden eliminar de su dispositivo. Además, según el tipo de navegador web y la versión de navegador que esté utilizando, es posible que pueda cambiar las propiedades de su archivo de cookies para que las cookies no se utilicen ni se guarden. Consulte con el proveedor de su navegador para obtener más información sobre la eliminación de cookies.",
  webSites: "Sitios web y/o aplicaciones de terceros",
  webSitesDescription:
    "Compassion International República Dominicana no tiene control sobre las políticas o practicas de privacidad de sitios web o aplicaciones de terceros, aun estas estén enlazadas de alguna manera a la pagina web elmundodeabby.tv, en tal sentido los usuarios y visitantes deben consultar las políticas de privacidad de estos sitios de terceros.",
  changeInThisPrivacyPolicy: "Cambios en esta política de privacidad",
  changeInThisPrivacyPolicyDescription:
    "Compassion International República Dominicana se reserva el derecho de modificar o actualizar esta política de privacidad para ajustarse y cumplir con leyes aplicables. Los usuarios y visitantes deben consultar las fechas de entrada en vigor y/o actualización de la política de privacidad en la parte superior de este documento para saber cuando se modifico por ultima vez.",
};

export default messages;
