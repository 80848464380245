import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";
import AboutUs from "../views/aboutUs.vue";
import Activities from "../views/activities.vue";
import Videos from "../views/videoGallery.vue";
import ContactUs from "../views/contactUs.vue";
import LanguagePage from "../views/languagePage.vue";
import MediaPlayer from "../views/mediaPlayer.vue";
import Terms from "../views/terms.vue";
import Policies from "../views/policies.vue";
import utils from "../utils";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/activities-gallery",
    name: "ActivitiesGallery",
    component: Activities,
  },
  {
    path: "/adventures-gallery",
    name: "AdventuresGallery",
    component: Videos,
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: "/languages",
    name: "Languages",
    component: LanguagePage,
  },
  {
    path: "/aventures-gallery/:slug",
    name: "MediaPlayer",
    component: MediaPlayer,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
  {
    path: "/policies",
    name: "Policies",
    component: Policies,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      if (to.hash) {
        utils.waitForElm(to.hash).then(() =>
          resolve({
            selector: to.hash,
            behavior: "smooth",
          })
        );
      } else {
        if (!savedPosition) {
          return { x: 0, y: 0 };
        }
      }
    });
  },
});

export default router;
