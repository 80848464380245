const messages = {
  contactUs: "Contáctanos",
  writteUs:
    "Escríbenos un mensaje si deseas conocer más información sobre el <br class='d-none d-lg-flex' /> proyecto del El Mundo de Abby.",
  name: "Nombre",
  email: "Correo",
  message: "Mensaje",
  btnSend: "Enviar",
  followUs:
    " Sigue a Abby en sus aventuras a través de las <b>redes sociales</b>.",
  sendMessage: "Tu mensaje ha sido enviado correctamente",
  requiredField: "Campo requerido",
  emailValidation: "El correo electrónico no es válido",
  charactersMaximum: "60 caracteres máximo",
};
export default messages;
