<template>
  <v-container
    class="media-player-container px-sm-10 px-10 px-lg-0"
    v-if="getEpisode"
  >
    <v-row class="flex-column-reverse flex-lg-row">
      <v-col cols="12" lg="7">
        <h3
          class="
            text-lg-h3 text-h5 text-center text-lg-left text-green
            font-weight-bold
          "
        >
          {{ $t("videoGallery.season") }}{{ getEpisode.season.number }}:{{
            $t("videoGallery.episode")
          }}{{ getEpisode.number }} - {{ getEpisode.title }}
        </h3>
      </v-col>
      <v-col cols="12" lg="5" class="d-flex justify-lg-end justify-center">
        <v-btn
          class="
            btn-return-video-gallery
            font-weight-bold
            px-lg-5
            d-none d-lg-flex
          "
          v-html="$t('videoGallery.returnTotheAdventureGallery')"
          to="/adventures-gallery"
        >
        </v-btn>
        <v-btn
          class="btn-return-video-gallery font-weight-bold px-lg-5 d-lg-none"
          v-html="$t('videoGallery.return')"
          to="/adventures-gallery"
        >
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-lg-10 video-container">
      <div id="player"></div>
    </v-row>
    <v-row class="mx-0 py-lg-10 flex-column-reverse flex-lg-row">
      <v-col cols="12" lg="6">
        <v-row class="mt-lg-n10 mb-lg-5">
          <v-col cols="11">
            <v-row>
              <v-col
                v-for="item in getEpisode.tags"
                :key="item.id"
                class="px-lg-0 mr-3"
                cols="auto"
              >
                <v-btn
                  :style="[{ background: item.color }]"
                  class="
                    btn-tag
                    mx-auto mx-sm-0
                    text-white
                    font-weight-bold
                    elevation-0
                  "
                >
                  {{ item.name[lang] }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              :color="getEpisode.current_user_liked ? 'pink' : 'gray'"
              min-width="30"
              class="ml-lg-10 btn-like"
              @click="setToggleLike()"
            >
              <v-icon>mdi-heart</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <h4 class="text-lg-h4 text-h5 font-weight-bold py-5">
            {{ $t("videoGallery.sypnosis") }}
          </h4>
        </v-row>
        <v-row>
          <p class="text-body-1">{{ getEpisode.description }}</p>
        </v-row>
        <v-row
          class="mt-lg-5"
          v-if="
            getEpisode.activities.filter(
              (activity) => activity.type !== 'games'
            ).length > 0
          "
        >
          <h4 class="text-lg-h4 text-h5 font-weight-bold py-5">
            {{ $t("videoGallery.downloadable") }}
          </h4>
        </v-row>
        <v-row v-if="getEpisode.activities.length > 0" class="mx-lg-0">
          <div>
            <v-row
              class="
                fifty-radius
                border-white
                background-yellow
                py-5
                elevation-3
                pl-lg-16 pl-10
              "
            >
              <v-col
                cols="auto"
                class="pr-lg-16 pr-l0 pl-0"
                v-if="validateActivity('drawings')"
              >
                <a
                  class="text-dark"
                  :href="getDrawing(getEpisode.activities)"
                  download
                  target="_blank"
                >
                  <v-avatar size="56" class="background-dark d-flex mx-auto">
                    <img src="/assets/mediaPlayer/dibujo.svg" class="pa-2" />
                  </v-avatar>
                  <h5
                    class="
                      text-lg-h5 text-h6 text-center
                      font-weight-bold
                      text-uppercase
                    "
                  >
                    {{ $t("videoGallery.drawing") }}
                  </h5>
                </a>
              </v-col>
              <v-col
                cols="auto"
                class="pr-lg-16 pr-10"
                v-if="validateActivity('games')"
              >
                <a
                  class="text-dark"
                  :href="getGame(getEpisode.activities)"
                  target="_blank"
                >
                  <v-avatar size="56" class="background-dark d-flex mx-auto">
                    <img src="/assets/mediaPlayer/game.svg" class="pa-2" />
                  </v-avatar>
                  <h5
                    class="
                      text-lg-h5 text-h6 text-center
                      font-weight-bold
                      text-uppercase
                    "
                  >
                    {{ $t("activities.games") }}
                  </h5>
                </a>
              </v-col>
              <v-col
                cols="auto"
                class="pr-lg-10 pr-10"
                v-if="validateActivity('crafts')"
              >
                <a
                  class="text-dark"
                  :href="getCraft(getEpisode.activities)"
                  target="_blank"
                >
                  <v-avatar size="56" class="background-dark d-flex mx-auto">
                    <img
                      src="/assets/mediaPlayer/manualidad.svg"
                      style="width: 35px !important"
                    />
                  </v-avatar>
                  <h5
                    class="
                      text-lg-h5 text-h6 text-center
                      font-weight-bold
                      text-uppercase
                    "
                  >
                    {{ $t("videoGallery.craft") }}
                  </h5>
                </a>
              </v-col>
            </v-row>
          </div>
        </v-row>
        <v-row
          class="mt-lg-10"
          v-if="
            getEpisode.comments.filter((comment) => comment.status === true)
              .length > 0
          "
        >
          <h4 class="text-lg-h4 text-h5 font-weight-bold py-5">
            {{ $t("videoGallery.comments") }}
          </h4>
        </v-row>
        <v-row
          v-for="(item, index) in getEpisode.comments.filter(
            (comment) => comment.status === true
          )"
          :key="index"
        >
          <p class="text-body-1">
            <span class="text-lg-h5 text-h6 font-weight-bold">
              {{ item.name["en"] }}</span
            ><br />
            {{ item.comment["en"] }}
          </p>
        </v-row>
        <v-row class="justify-lg-end d-lg-none mb-10">
          <div style="max-width: 100%; min-width: 100%">
            <h4 class="text-lg-h4 text-h5 font-weight-bold py-5">
              {{ $t("videoGallery.comment") }}
            </h4>
            <v-text-field
              v-model="comment.name"
              :label="$t('videoGallery.name')"
              class="twenty-radius"
              required
              solo
            ></v-text-field>
            <v-textarea
              v-model="comment.comment"
              solo
              name="input-7-4"
              class="twenty-radius mt-n4"
              :label="$t('videoGallery.message')"
            ></v-textarea>
            <v-btn
              class="
                background-yellow
                border-white
                fifty-radius
                py-5
                px-10
                font-weight-black
                text-lg-h5 text-h6
                mx-auto
                d-flex
              "
              @click="createCommentToEpisode()"
            >
              {{ $t("videoGallery.btnSend") }}
            </v-btn>
          </div></v-row
        >
      </v-col>
      <v-col cols="12" lg="6">
        <v-row class="justify-lg-end mr-lg-n5 ml-lg-5">
          <div class="card-episodes pb-10">
            <v-row class="mx-lg-0 mr-3">
              <v-col cols="6" class="d-flex align-center pl-10">
                <h5
                  class="
                    text-white text-lg-h5 text-h6
                    font-weight-bold
                    text-uppercase
                  "
                  v-if="lastEpisode"
                >
                  {{ $t("videoGallery.viewAgain") }}
                </h5>
                <h5
                  v-else
                  class="
                    text-white text-lg-h5 text-h6
                    font-weight-bold
                    text-uppercase
                  "
                >
                  {{ $t("videoGallery.nextEpisode") }}
                </h5>
              </v-col>
              <v-col cols="6">
                <v-row>
                  <v-col cols="7" class="d-flex align-center justify-end">
                    <h6
                      v-html="$t('videoGallery.playVideosAutomatically')"
                      class="text-white text-uppercase text-justify font-10"
                    ></h6>
                  </v-col>
                  <v-col cols="5">
                    <v-switch
                      inset
                      color="#242525"
                      class="switch-episode"
                      v-model="reproduction"
                      @change="reproductionAutomatically()"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <a
              v-for="item in nextEpisodes.slice(0, 3)"
              :key="item.id"
              :href="'/aventures-gallery/' + item.slug"
            >
              <v-row class="mx-5 d-none d-lg-flex my-2">
                <v-col cols="5" lg="4" sm="3">
                  <v-img
                    :src="
                      'https://img.youtube.com/vi/' +
                      getVideoId(item.url).id +
                      '/0.jpg'
                    "
                    class="border-white-8"
                    height="80"
                  />
                </v-col>
                <v-col cols="7" lg="8" sm="9">
                  <v-row>
                    <h5
                      class="
                        text-lg-h5 text-h6 text-white
                        font-weight-bold
                        pt-2
                      "
                    >
                      {{ item.title }}
                    </h5>
                  </v-row>
                  <v-row>
                    <p class="text-body-1 text-white resume-sypnosis">
                      {{ item.description }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </a>
            <a
              v-for="item in nextEpisodes.slice(0, 1)"
              :key="item.id + 'xs'"
              :href="'/aventures-gallery/' + item.slug"
              class="my-2"
            >
              <v-row class="mx-5 d-lg-none">
                <v-col cols="5" lg="4" sm="3">
                  <v-img
                    :src="
                      'https://img.youtube.com/vi/' +
                      getVideoId(item.url).id +
                      '/0.jpg'
                    "
                    class="border-white-8"
                    height="100"
                  />
                </v-col>
                <v-col cols="7" lg="8" sm="9">
                  <v-row>
                    <h5
                      class="
                        text-lg-h5 text-h6 text-white
                        font-weight-bold
                        pt-2
                      "
                    >
                      {{ item.title }}
                    </h5>
                  </v-row>
                  <v-row>
                    <p class="text-body-1 text-white resume-sypnosis">
                      {{ item.description }}
                    </p>
                  </v-row>
                </v-col>
              </v-row>
            </a>
          </div>
        </v-row>
        <v-row class="justify-lg-end d-none d-lg-flex">
          <div style="max-width: 450px; min-width: 450px">
            <h4 class="text-h4 font-weight-bold py-5">
              {{ $t("videoGallery.comment") }}
            </h4>
            <v-text-field
              v-model="comment.name"
              :label="$t('videoGallery.name')"
              class="twenty-radius"
              required
              solo
            ></v-text-field>
            <v-textarea
              v-model="comment.comment"
              solo
              name="input-7-4"
              class="twenty-radius mt-n4"
              :label="$t('videoGallery.message')"
            ></v-textarea>
            <v-btn
              class="
                background-yellow
                border-white
                fifty-radius
                py-5
                px-10
                font-weight-black
                text-h5
                mx-auto
                d-flex
              "
              @click="createCommentToEpisode()"
            >
              {{ $t("videoGallery.btnSend") }}
            </v-btn>
          </div></v-row
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "mediaPlayer",
  data() {
    return {
      comment: {
        name: null,
        episode_id: "",
        comment: null,
      },
      player: null,
      done: false,
      nextEpisodeList: [],
      reproduction: localStorage.getItem("reproduction") || false,
      lang: localStorage.getItem("language") || "en",
      lastEpisode: false,
      disabled: true,
    };
  },
  async created() {
    await this.fetchGetEpisodes();
    const episode = this.getEpisodes.find(
      (episode) => episode.slug === this.$route.params.slug
    );
    await this.fetchGetEpisode(episode.id);
    await this.createReproductionAutomatically();
    await this.validateLastEpisode();
  },
  computed: {
    ...mapGetters("episodes", ["getEpisodes", "getEpisode"]),
    ...mapGetters("seasons", ["getSeasons"]),
    nextEpisodes: {
      get: function () {
        return this.getNextEpisode();
      },
      set: function (val) {
        this.nextEpisodeList = val;
      },
    },
  },
  methods: {
    ...mapActions("episodes", [
      "fetchGetEpisode",
      "fetchGetEpisodes",
      "setLike",
    ]),
    ...mapActions("seasons", ["fetchGetSeasons"]),
    ...mapActions("comments", ["createComment"]),
    createCommentToEpisode() {
      if (this.comment.comment !== null && this.comment.name !== null) {
        this.createComment(this.comment);
        Swal.fire({
          title: this.$t("videoGallery.commentMessage"),
        });
        this.comment.name = "";
        this.comment.comment = "";
      }
    },
    validateActivity(type) {
      return this.getEpisode.activities
        .map((activity) => activity.type[this.lang])
        .includes(type);
    },
    getDrawing(uploads) {
      if (uploads) {
        var uploadPdf = uploads.find(
          (item) => item.type[this.lang] === "drawings"
        );
        return uploadPdf.uploads.find((up) => up.main === true).path;
      }
    },
    getGame(uploads) {
      if (uploads) {
        var uploadPdf = uploads.find(
          (item) => item.type[this.lang] === "games"
        );
        return uploadPdf.uploads.find((up) => up.main === true).path;
      }
    },
    getCraft(uploads) {
      if (uploads) {
        var uploadPdf = uploads.find(
          (item) => item.type[this.lang] === "crafts"
        );
        return uploadPdf.uploads.find((up) => up.main === true).path;
      }
    },
    createReproductionAutomatically() {
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      this.onYouTubeIframeAPIReady();
    },
    async onYouTubeIframeAPIReady() {
      // eslint-disable-next-line no-undef
      this.player = await new YT.Player("player", {
        height: "100%",
        width: "100%",
        playerVars: {
          autoplay: 1,
          modestbranding: 1,
          rel: 0,
          showinfo: 0,
        },
        videoId: this.getVideoId(this.getEpisode.url).id.toString(),
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onPlayerStateChange,
        },
      });
    },
    onPlayerReady(event) {
      event.target.playVideo();
    },
    onPlayerStateChange(event) {
      switch (event.data) {
        // eslint-disable-next-line no-undef
        case YT.PlayerState.ENDED:
          if (localStorage.getItem("reproduction")) {
            this.$router.push(
              "/aventures-gallery/" + this.nextEpisodes[0].slug
            );
            this.$router.go();
          }
          break;
      }
    },
    reproductionAutomatically() {
      if (this.reproduction) {
        localStorage.setItem("reproduction", "true");
      } else {
        localStorage.setItem("reproduction", "");
      }
    },
    getNextEpisode() {
      if (this.getEpisodes.length === 0) {
        return this.nextEpisodeList;
      }
      var episodes = this.getEpisodes.filter(
        (episode) =>
          episode.number > this.getEpisode.number &&
          episode.season.number === this.getEpisode.season.number
      );
      let episodesNextSeasons = this.getEpisodes.filter(
        (episode) => episode.season.number > this.getEpisode.season.number
      );
      if (episodesNextSeasons) {
        episodes = episodes.concat(episodesNextSeasons);
      }
      if (episodes.length === 0) {
        episodes = this.getEpisodes;
      }
      return episodes;
    },
    validateLastEpisode() {
      let number = this.getEpisodes.length - 1;
      let lastEpisodeLocal = this.getEpisodes[number];
      if (
        lastEpisodeLocal.number === this.getEpisode.number &&
        lastEpisodeLocal.season.number === this.getEpisode.season.number
      ) {
        this.lastEpisode = true;
      }
    },
    setToggleLike() {
      this.setLike(this.getEpisode.id);
      this.getEpisode.current_user_liked = !this.getEpisode.current_user_liked;
    },
  },
  watch: {
    getEpisode(val) {
      this.comment.episode_id = val.id;
    },
  },
};
</script>
