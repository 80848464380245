<template>
  <v-container
    fluid
    class="crafts-container"
    v-if="games.length > 0"
    id="games"
  >
    <title-1 :title="$t('activities.games')"></title-1>
    <v-row class="mt-10">
      <v-col
        v-for="(item, index) in games.slice(0, num)"
        :key="index"
        cols="12"
        sm="6"
        lg="4"
        class="py-lg-5 pr-lg-5"
      >
        <v-card
          class="card-activities pa-0 ma-lg-0 mx-auto"
          :href="getPdf(item.uploads)"
          download="test"
          target="_blank"
          :ripple="false"
        >
          <v-img :src="getImage(item.uploads)" height="145"> </v-img>
          <v-card-title class="text-white text-h6">{{
            item.title
          }}</v-card-title>
          <v-card-actions class="justify-end mt-n3 mx-0 pb-0">
            <v-btn
              class="btn-green px-5 mr-n2 text-h6 font-weight-black"
              :href="item.url"
              download
              target="_blank"
              v-if="item.url"
            >
              {{ $t("activities.btnUrl") }}
            </v-btn>
            <v-btn
              class="btn-green px-5 mr-n2 text-h6 font-weight-black"
              :href="getPdf(item.uploads)"
              download
              target="_blank"
              v-else
            >
              {{ $t("activities.btnDownload") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center mb-10">
      <v-btn
        class="first-btn px-8 py-7 mt-16"
        @click="changeGames()"
        v-if="games.length > 5"
      >
        {{ $t("activities.btnShowMoreGame") }}</v-btn
      >
    </v-row>
  </v-container>
</template>
<script>
import title1 from "../titles/title1.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { title1 },
  name: "games",
  data() {
    return {
      num: 6,
      gamesList: [],
    };
  },
  async created() {
    this.fetchGetActivities();
  },
  computed: {
    ...mapGetters("activities", ["getActivities"]),
    ...mapGetters("tags", ["getTagsActive"]),
    games: {
      get: function () {
        return this.getGamesByTags();
      },
      set: function (val) {
        this.gamesList = val;
      },
    },
  },
  methods: {
    ...mapActions("activities", ["fetchGetActivities"]),
    changeGames() {
      this.num = this.num + 6;
    },
    getGamesByTags() {
      if (this.getTagsActive.length === 0) {
        return this.gamesList;
      }
      return this.gamesList.filter((game) =>
        game.tags.find((tag) =>
          this.getTagsActive
            .map((activeTag) => activeTag.name)
            .includes(tag.name[localStorage.getItem("language")])
        )
      );
    },
    getPdf(uploads) {
      if (uploads) {
        var uploadPdf = uploads.find((item) => item.main === true);
        return uploadPdf.path;
      }
    },
    getImage(uploads) {
      if (uploads) {
        var uploadImage = uploads.find((item) => item.main === false);
        return uploadImage?.path;
      }
    },
  },
  watch: {
    getActivities(val) {
      this.games = val.filter((games) => games.type === "games");
    },
  },
};
</script>
