<template>
  <v-container
    fluid
    class="drawings-container"
    v-if="drawings.length > 0"
    id="drawings"
  >
    <title-1 :title="$t('activities.drawings')"></title-1>
    <v-row>
      <v-col
        v-for="(item, index) in drawings.slice(0, num)"
        :key="index"
        cols="12"
        sm="6"
        lg="4"
        class="py-lg-5 pr-lg-5"
      >
        <v-card
          class="
            card-activities
            pa-0
            ma-lg-0
            mx-auto
            align-content-space-between
          "
          flat
          :href="getPdf(item.uploads)" download="test" target="_blank"
          :ripple="false"
        >
          <v-img
            v-if="getImage(item.uploads)"
            :src="getImage(item.uploads)"
            height="145"
          >
          </v-img>
          <v-card-title class="text-white text-h6">{{
            item.title
          }}</v-card-title>
          <v-card-actions class="justify-end mt-n3 mx-0 pb-0">
            <a :href="getPdf(item.uploads)" download="test" target="_blank">
              <v-btn class="btn-green px-5 mr-n2 text-h6 font-weight-black">
                {{ $t("activities.btnDownload") }}
              </v-btn>
            </a>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        class="first-btn px-8 py-7 mt-16"
        @click="changeDrawings()"
        v-if="drawings.length > 5"
      >
        {{ $t("activities.btnShowMoreDrawings") }}</v-btn
      >
    </v-row>
  </v-container>
</template>
<script>
import title1 from "../titles/title1.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { title1 },
  name: "drawings",
  data() {
    return {
      num: 6,
      src: "/assets/prueba.jpg",
      drawingsList: [],
    };
  },
  async created() {
    this.fetchGetActivities();
  },
  computed: {
    ...mapGetters("activities", ["getActivities"]),
    ...mapGetters("tags", ["getTagsActive"]),
    drawings: {
      get: function () {
        return this.getDrawingByTags();
      },
      set: function (val) {
        this.drawingsList = val;
      },
    },
  },
  methods: {
    ...mapActions("activities", ["fetchGetActivities"]),
    changeDrawings() {
      this.num = this.num + 6;
    },
    getDrawingByTags() {
      if (this.getTagsActive.length === 0) {
        return this.drawingsList;
      }
      return this.drawingsList.filter((drawing) =>
        drawing.tags.find((tag) =>
          this.getTagsActive
            .map((activeTag) => activeTag.name)
            .includes(tag.name[localStorage.getItem("language")])
        )
      );
    },
    getPdf(uploads) {
      if (uploads) {
        var uploadPdf = uploads.find((item) => item.main === true);
        return uploadPdf.path;
      }
    },
    getImage(uploads) {
      if (uploads) {
        var uploadImage = uploads.find((item) => item.main === false);
        return uploadImage?.path;
      }
    },
  },
  watch: {
    getActivities(val) {
      this.drawings = val.filter((drawing) => drawing.type === "drawings");
    },
  },
};
</script>
