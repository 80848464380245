import commentsServices from "../../services/comments";

export default {
  state: () => ({
    comment: [],
  }),
  getters: {
    getComment: (state) => state.comment,
  },
  actions: {
    async createComment({ commit }, data) {
      const { data: commentData } = await commentsServices.addCommentToEpisode(
        data
      );
      commit("setComment", commentData);
    },
  },
  mutations: {
    setComment(state, data) {
      state.comment = data;
    },
  },
  namespaced: true,
};
