var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"header-bar py-lg-16 py-5 px-sm-16 px-lg-0",attrs:{"id":"header"}},[_c('v-row',[_c('v-col',{staticClass:"d-lg-none d-flex flex-column justify-center",attrs:{"cols":"4"}},[_c('router-link',{attrs:{"to":"/adventures-gallery"}},[_c('v-img',{staticClass:"colmena mx-auto mt-7 mb-sm-n5",attrs:{"src":"/assets/header/colmena.png"}})],1),_c('v-row',[_c('v-img',{staticClass:"burbuja d-flex align-center mt-2 ml-n2 mt-sm-0",attrs:{"src":"/assets/header/burbuja.svg","width":"110","min-width":"110","contain":""}},[_c('v-container',{staticClass:"mt-4"},[_c('p',{staticClass:"text-header text-center font-weight-bold mt-1",domProps:{"innerHTML":_vm._s(_vm.$t('menu.videoGallery'))}})])],1)],1)],1),_c('v-col',{attrs:{"cols":"4","lg":"4"}},[_c('a',{attrs:{"href":"/"}},[_c('v-img',{staticClass:"ml-lg-n10 logo mx-auto",attrs:{"src":"/assets/header/logo.png","contain":""}})],1)]),_c('v-col',{staticClass:"d-lg-none d-flex justify-sm-end pr-sm-16",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"stickflag ml-10 mt-10 mt-sm-16 mr-md-16"},[_c('v-row',{staticClass:"justify-center"},[_c('v-select',{staticClass:"mx-auto mt-n12 ml-n2",attrs:{"items":_vm.getLanguages,"item-value":"code"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"flag_container ml-n4"},[_c('country-flag',{staticClass:"ml-n2",attrs:{"country":item.flag}}),_c('p',{staticClass:"\n                    ml-10\n                    mt-n9\n                    mr-3\n                    text-uppercase\n                    font-weight-bold\n                    no-wrap\n                  ",domProps:{"innerHTML":_vm._s(_vm.certifiedLang(item.code))}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"flag_container_list",on:{"click":function($event){return _vm.saveLang(item.code)}}},[_c('country-flag',{attrs:{"country":item.flag}})],1)]}}]),model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}})],1)],1)],1),_c('v-col',{staticClass:"justify-center d-lg-flex d-none",attrs:{"cols":"12","lg":"8"}},[_c('v-row',{staticClass:"px-8 justify-center absolute mr-n16 ml-5"},[_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index},[_c('v-card',{staticClass:"card-header mt-10",attrs:{"to":item.to}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-img',{staticClass:"mx-auto mt-n10 icon-menu",attrs:{"src":item.icon,"width":"52","height":"70","contain":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"text-header text-center font-weight-bold",domProps:{"textContent":_vm._s(_vm.$t(item.title))}})],1)],1)],1)}),_c('v-col',{staticClass:"colmena-parent"},[_c('router-link',{attrs:{"to":"/adventures-gallery"}},[_c('v-img',{staticClass:"mb-10 mt-n8 colmena",attrs:{"src":"/assets/header/colmena.png","width":"110"}})],1),_c('v-img',{staticClass:"burbuja",attrs:{"src":"/assets/header/burbuja.svg","width":"140","contain":""}},[_c('v-container',{staticClass:"ma-0"},[_c('p',{staticClass:"text-header text-center font-weight-bold mt-1",domProps:{"innerHTML":_vm._s(_vm.$t('menu.videoGallery'))}})])],1)],1),_c('v-col',[_c('v-card',{staticClass:"stickflag ml-10 mt-7"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-select',{staticClass:"mx-auto mt-n12 ml-n2",attrs:{"items":_vm.getLanguages,"item-value":"code"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"flag_container ml-n3"},[_c('country-flag',{attrs:{"country":item.flag}}),_c('p',{staticClass:"ml-12 mt-n9 mr-4 text-uppercase font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.certifiedLang(item.code))}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"flag_container_list",on:{"click":function($event){return _vm.saveLangAndUpdateURL(item.code)}}},[_c('country-flag',{attrs:{"country":item.flag}})],1)]}}]),model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}})],1)],1)],1)],2),_c('v-row',{staticClass:"absolute grama-menu mr-n16 ml-5"},[_c('v-img',{attrs:{"src":"/assets/header/grama.png","contain":"","width":"750"}})],1)],1)],1),_c('v-row',{staticClass:"grama-menu d-lg-none mt-n14 mt-sm-n16"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":"/assets/header/grama.png"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }