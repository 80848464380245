const messages = {
  aboutAbbyWorld: "Sobre El Mundo de Abby",
  aboutAbbyWorldDescription:
    '"¡Bienvenidos a “El Mundo de Abby”!<br><br>El mundo de Abby es un programa infantil interactivo, basado en el currículo de Compassion International para el desarrollo holístico para niños con edades entre 3 y 9 años. El mundo de Abby se enfoca en las áreas espiritual, física, socioemocional y cognitiva de desarrollo, presentadas por Wes quien desempeña el papel de tutora de su amiguita la abejita Abby. Ambas aprenden juntas sobre las escrituras, historias bíblicas y actividades de la vida diaria mientras pasan un momento increíble.',
  aboutAbbyWorldDescription2:
    "El Mundo de Abby, tiene como propósito educar y apoyar a los niños en temas como la familia, el amor propio, su relación con las demás personas, el medio ambiente, la higiene, las emociones entre otros, todos ellos desarrollados desde la cosmovisión bíblica y Cristo céntrica que permita estimular los valores, el pensamiento y el bienestar físico del niño de acuerdo con su edad.",
  aboutCompassion: "Sobre Compassion",
  aboutCompassionDescription:
    "Compassion International somos un ministerio de defensa a la niñez que une a personas compasivas con aquellos viviendo en pobreza. Nuestra misión es Liberar a los niños de la pobreza en el nombre de Jesús. La meta es que cada niño sea un adulto responsable, completo y realizado.",
  sponsorAChild: "patrocina un niño",
};
export default messages;
