import http from "./axios";

export default {
  async sendEmail(email) {
    try {
      return await http.post(`/api/contact`, email);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};
