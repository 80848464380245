import activitiesServices from "../../services/activities";

export default {
  state: () => ({
    activities: [],
  }),
  getters: {
    getActivities: (state) => state.activities,
  },
  actions: {
    async fetchGetActivities({ commit }) {
      const { data: activitiesData } = await activitiesServices.getActivities();
      commit("setActivities", activitiesData.data);
    },
  },
  mutations: {
    setActivities(state, data) {
      state.activities = data;
    },
  },
  namespaced: true,
};
