<template>
  <v-container fluid class="recentvideo-container pa-0">
    <v-row>
      <a class="episode-link mx-auto" :href="'aventures-gallery/' + episodes[0].slug" >
        <v-img src="/assets/abbybanner.png" class="last-video align-end">
          <v-container>
            <v-row justify="end">
              <h4
                class="
                  text-lg-h3
                  text-h5
                  text-md-h4
                  text-center
                  text-green
                  text-uppercase
                  last-episode
                  mr-sm-5
                "
                v-html="$t('home.haveYouSeenOurLastEpisode')"
              ></h4>
            </v-row>
            <v-row class="justify-end">
              <v-col
                cols="6"
                sm="3"
                lg="3"
                class="background-yellow d-flex justify-center align-center"
              >
                <v-btn
                  class="
                    text-lg-h4 text-h6
                    transparent
                    elevation-0
                    text-center
                    font-weight-bold
                    text-dark
                    opacity
                    py-lg-10
                    px-lg-16
                  "
                  :ripple="false"
                  v-if="episodes"
                  :to="'aventures-gallery/' + episodes[0].slug"
                  plain
                  >{{ $t("home.btnShowNow") }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </a>
    </v-row>
    <title-1
      class="mt-16 mt-lg-0"
      :title="$t('home.recentAdventures')"
    ></title-1>
    <v-container fluid class="mt-lg-n7">
      <v-row class="background-panal mx-3 mx-lg-0">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              lg="4"
              v-for="(item, index) in episodes"
              :key="index"
            >
            <a class="episode-link" :href="'aventures-gallery/' + item.slug" >
              <v-img
                :src="
                  'https://img.youtube.com/vi/' +
                  getVideoId(item.url).id +
                  '/0.jpg'
                "
                class="recent-aventures align-end mx-auto mx-lg-0"
              >
                <v-container>
                  <v-row class="justify-end">
                    <v-col
                      cols="4"
                      lg="3"
                      class="background-green py-3 px-lg-5"
                    >
                      <h5
                        class="
                          text-lg-h5 text-h6 text-center text-white
                          font-weight-bold
                        "
                      >
                        {{ $t("videoGallery.season")
                        }}{{ item.season.number }}:{{
                          $t("videoGallery.episode")
                        }}{{ item.number }}
                      </h5>
                    </v-col>
                    <v-col
                      cols="3"
                      lg="4"
                      class="
                        background-yellow
                        d-flex
                        justify-center
                        align-center
                      "
                    >
                      <v-btn
                        class="
                          text-body-2
                          transparent
                          elevation-0
                          text-center
                          font-weight-black
                        "
                        :to="'aventures-gallery/' + item.slug"
                        v-html="$t('home.btnShowNow')"
                        plain
                      ></v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-img>
              </a>
              
            </v-col>
          </v-row>
          <v-row class="justify-center py-5">
            <h4
              class="
                text-lg-h3 text-h4 text-green
                px-5 px-lg-0
                text-center text-lg-left
              "
              v-html="$t('home.wantToSeeMoreAbbyAdventures')"
            ></h4>
          </v-row>
          <v-row class="justify-center py-5">
            <v-btn
              class="first-btn px-5 font-weight-black"
              height="70"
              to="/adventures-gallery"
            >
              {{ $t("home.seeMoreAdventures") }}</v-btn
            >
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import title1 from "@/components/titles/title1.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "recentVideos",
  components: { title1 },

  data() {
    return {
      episodesList: [],
      episode: [],
    };
  },
  async created() {
    this.fetchGetEpisodes();
  },
  computed: {
    ...mapGetters("episodes", ["getEpisodes"]),
    episodes: {
      get: function () {
        return this.episodesSort();
      },
      set: function (val) {
        this.episodesList = val;
      },
    },
  },
  methods: {
    ...mapActions("episodes", ["fetchGetEpisodes"]),
    episodesSort() {
      let valNum = 3;
      let windowWidth = window.innerWidth;
      if (windowWidth < 1265) {
        valNum = 2;
      }
      if (this.episodesList.length > 0) {
        return this.episodesList
          .sort((a, b) => (a.season.number < b.season.number ? 1 : -1))
          .slice(0, valNum);
      }
    },
  },
  watch: {
    getEpisodes(val) {
      this.episodesList = val;
      // this.episode = this.episodes[0];
    },
  },
};
</script>
