import http from "./axios";

export default {
  async getEpisodes() {
    try {
      return await http.get(`/api/episodes`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async getEpisodeById(id) {
    try {
      return await http.get(`/api/episodes/${id}`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
  async like(episodeId) {
    try {
      return await http.post(`/api/episodes/like/${episodeId}`);
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
};
