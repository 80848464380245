const messages = {
  acceptanceOfConditionsOfUse: "Acceptance of the conditions of use",
  acceptanceOfConditionsOfUseDescription:
    "Welcome to elmundodeabby.tv, owned by Compassion International Dominican Republic. The services offered and described on this site are subject to the conditions of use contained in this document, please read carefully. These services include any URL of the brand Abby’s World, the website elmundodeabby.tv and any other website, content, applications or games that are offered or are related to elmundodeabby.tv.<br><br>Compassion International Dominican Republic offers elmundodeabby.tv, its content, applications and activities for the edification, education, enjoyment and personal entertainment, either individually or in groups. By accessing this site or making use of its content, you accept and agree to the terms and conditions indicated in this document. Compassion International Dominican Republic reserves the right to modify these conditions of use at any time, and each modification takes effect when they are published on elmundodeabby.tv. The continued use of the elmundodeabby.tv site, its content, applications or activities, after any modification constitutes its acceptance and agreement, for which the user is bound under the modified agreement.",
  copyright: "Copyright",
  copyrightDescription:
    "All content included in this website, such as text, graphics, logos, photographs, buttons, icons, images, audio clips, downloadable files, software, data compilation, is the exclusive property of Compassion International Dominican Republic or its suppliers. content and / or services and are subject to international copyright laws.",
  licenseAndAccessToWebSite: "License and access to the website",
  licenseAndAccessToWebSiteDescription:
    "Compassion International Dominican Republic, grants you a limited, non-exclusive, non-transferable and revocable license to access and make personal use of this website and does not allow downloading or modifying all or part of this website, except with the written consent of Compassion International RD. This license does not include resale or commercial use of this site or its content; the collection and use of any information, images, content, photographs, videos, or any use derived from this site or its content; downloading or copying the information for the financial benefit of a third party; or the use of data mining, robots or similar data extraction and collection tools. This site or any portion of it must not be reproduced, duplicated, copied, sold, resold or in any other way exploited for any commercial purpose, without the express written consent of Compassion International Dominican Republic.",
  disclaimerOfWarranties:
    "Disclaimer of warranties and Limitation of Liability",
  disclaimerOfWarrantiesDescription:
    "This site is provided by Compassion International Dominican Republic, 'As' and 'As available'. Compassion International Dominican Republic makes no representations or warranties of any kind, express or implied, regarding the operation of this site or the information, content or materials included in this site, nor does it offer guarantees or promises of specific results from the use of the materials. and the content published on elmundodeabby.tv. You expressly agree that use of this site is at your own risk. Compassion International Dominican Republic does not guarantee that this site, its servers or the email sent from the elmundodeabby.tv website are free of viruses or other harmful components. Compassion International Dominican Republic will not be liable for any damages of any kind arising from the use of this site, including, but not limited to, indirect, incidental, punitive and consequential damages.",
  commentPanels: "Comment or message panels",
  commentPanelsDescription:
    "Within the elmundodeabby.tv site, users are offered the possibility of posting public messages and / or comments in comment forums or messages open to the public. By posting a comment or message on one of these panels within the elmundodeabby.tv website, you acknowledge that said messages or comments are of a public nature and that Compassion International Dominican Republic cannot guarantee the security of the information that you publish through these, therefore, you make these publications at your own risk. Compassion International Dominican Republic is not responsible for the content posted by users or visitors to elmundodeabby.tv and reserves the right to restrict or remove the content, for any reason and at any time.",
  linkToExternalSites: "Links to external sites and social networks",
  linkToExternalSitesDescription:
    "Within the elmundodeabby.tv site, access links are provided to external sites, whether of Compassion International Dominican Republic, Compassion International INC, or the sites of any of Compassion International's global partners. Likewise, external links are provided to social networking services of Abby’s World, Compassion International Dominican Republic or any of the Global partners of Compassion International INC. When you interact with any of these external sites, you must agree with the privacy policies and conditions of use provided by that external site, independently of the privacy policies and conditions of use of elmundodeabby.tv. Compassion International Dominican Republic is not responsible or offers guarantees, express or implied, regarding the services offered by external sites and / or social networks. Compassion International Dominican Republic is not responsible for the content or practices of websites external to elmundodeabby.tv even if they are linked to elmundodeabby.tv or are operated by companies related in any way to Compassion International Dominican Republic.",
  contactInformation: "Contact Information",
  contactInformationDescription:
    "If you have questions, comments or complaints regarding our privacy policies or conditions of use, you can contact us at info.rd@compassion.com.",
};
export default messages;
