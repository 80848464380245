import seasonsServices from "../../services/seasons";

const seasons = {
  state: () => ({
    seasons: null,
  }),
  getters: {
    getSeasons: (state) => state.seasons,
  },
  actions: {
    async fetchGetSeasons({ commit }) {
      const { data: seasonsData } = await seasonsServices.getSeasons();
      commit("setSeasons", seasonsData.data);
    },
  },
  mutations: {
    setSeasons(state, data) {
      state.seasons = data;
    },
  },
  namespaced: true,
};

export default seasons;
