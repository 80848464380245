<template>
  <v-container class="characters-container pt-lg-0 pt-16 pb-1 px-lg-0">
    <title-2 :title="$t('home.knowTheCharacters')"></title-2>
    <v-row class="pb-lg-16 mx-0 px-10 px-lg-0 mx-lg-n7">
      <v-col
        v-for="(item, index) in characters.slice(0, num)"
        :key="index"
        cols="12"
        md="6"
        :lg="colNum"
        class="mb-lg-5"
      >
        <v-row class="mx-n3 justify-center">
          <v-col cols="12" lg="5">
            <v-card
              class="card-character transparent elevation-0 d-flex mx-auto"
            >
              <v-card class="border-parent align-end d-flex">
                <v-img
                  :src="item.upload.path"
                  class="mb-n5"
                  contain
                  height="240"
                  max-height="240"
                />
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" lg="7" class="pl-lg-3 align-center d-flex">
            <v-row class="justify-center justify-lg-start">
              <h5 class="text-h5 text-green font-weight-black">
                {{ item.name }}
              </h5>
              <br />
              <p
                class="
                  text-body-1 text-center text-lg-left
                  characters-description
                "
              >
                {{ item.description }}
              </p>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      class="
        ma-sm-0
        mt-16
        justify-end justify-md-center justify-lg-end
        mt-lg-n10
        pr-16 pr-lg-0
      "
    >
      <v-card class="poster mt-n10 justify-center d-flex align-center ml-8">
        <a
          class="text-dark text-h5 font-weight-bold text-uppercase"
          href="/about-us#characters"
        >
          {{ $t("home.btnKnowMore") }}
        </a>
      </v-card>
      <v-card class="stickflag mr-14"> </v-card
    ></v-row>
  </v-container>
</template>

<script>
import title2 from "../titles/title2.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { title2 },
  name: "characters",
  props: ["colNum", "num"],
  data() {
    return {
      characters: [],
    };
  },
  created() {
    this.fetchGetCharacters();
  },
  computed: {
    ...mapGetters("characters", ["getCharacters"]),
  },
  methods: {
    ...mapActions("characters", ["fetchGetCharacters"]),
  },
  watch: {
    getCharacters(val) {
      this.characters = val.filter((character) => character.status);
    },
  },
};
</script>
