<template>
  <v-container fluid class="aboutUs">
    <title-3 :title="$t('aboutUs.aboutAbbyWorld')"></title-3>
    <v-row class="background-panal mt-16">
      <v-container class="pa-0">
        <v-row class="px-10 px-lg-0 flex-column-reverse flex-lg-row">
          <v-col cols="12" lg="4" class="align-center d-flex">
            <p
              class="text-h5 text-center text-lg-start"
              v-html="$t('aboutUs.aboutAbbyWorldDescription')"
            ></p>
          </v-col>
          <v-col cols="12" lg="8">
            <v-img
              height="410"
              width="664"
              class="fifty-radius border-white ml-lg-auto mr-lg-0 mx-auto"
              src="/assets/aboutus.png"
            />
          </v-col>
        </v-row>
        <v-row class="px-10 px-lg-0">
          <v-col cols="12" class="align-center d-flex">
            <p
              class="text-h5 text-center text-lg-start"
              v-html="$t('aboutUs.aboutAbbyWorldDescription2')"
            ></p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
    <characters id="characters" colNum="6"></characters>
    <title-3 class="mt-16" :title="$t('aboutUs.aboutCompassion')"></title-3>
    <v-container class="px-0 py-10">
      <v-row class="flex-row-reverse">
        <v-col cols="12" lg="4">
          <a
            href="https://www.compassion.com/sponsor_a_child/child-search-results.htm?AreaFilter=Central+America+and+Caribbean&CountryFilter=Dominican+Republic&AgeMinFilter=1&AgeMaxFilter=22"
            target="_blank"
          >
            <v-img
              src="/assets/compassion.jpg"
              width="470"
              min-height="370"
              max-height="520"
              contain
              class="mx-auto mr-lg-auto ml-lg-0 border-white fifty-radius"
            ></v-img>
          </a>
        </v-col>
        <v-col class="d-flex align-center" cols="12" lg="8">
          <v-row class="px-10 px-lg-0">
            <p
              class="text-h5 text-center text-lg-left"
              v-html="$t('aboutUs.aboutCompassionDescription')"
            ></p>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Characters from "../components/home/characters.vue";
import title3 from "../components/titles/title3.vue";
export default {
  components: { title3, Characters },
  name: "aboutUs",
};
</script>
