
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcPlhU_krRUjDVCmHba_gQenqWqh_DrMM",
  authDomain: "elmundodeabby-15026.firebaseapp.com",
  projectId: "elmundodeabby-15026",
  storageBucket: "elmundodeabby-15026.appspot.com",
  messagingSenderId: "977824591206",
  appId: "1:977824591206:web:b20d16e376106b545eaca7",
  measurementId: "G-1F07P6V3P5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);