<template>
  <v-container class="justify-center d-flex menu-mobile-container">
    <v-row class="grama-green"></v-row>
    <v-row class="menu-mobile mx-auto mb-n8">
      <v-col
        v-for="(item, index) in items"
        :key="index"
        cols="4"
        class="px-1 px-sm-5 d-flex justify-center"
      >
        <v-card class="card-header elevation-0" :to="item.to">
          <v-row justify="center">
            <v-img
              :src="item.icon"
              width="52"
              height="50"
              contain
              class="mx-auto mt-n5 icon-menu"
            />
          </v-row>
          <v-row justify="center" class="align-center">
            <v-card-title
              v-text="$t(item.title)"
              class="text-header text-center font-weight-bold mt-5"
            ></v-card-title>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "menuMobile",
  data: () => ({
    //
    items: [
      {
        title: "menu.home",
        icon: "/assets/header/home.svg",
        to: {
          name: "Home",
        },
      },
      {
        title: "menu.aboutUs",
        icon: "/assets/header/aboutus.svg",
        to: {
          name: "AboutUs",
        },
      },
      {
        title: "menu.activities",
        icon: "/assets/header/actividades.svg",
        to: {
          name: "ActivitiesGallery",
        },
      },
    ],
  }),
};
</script>
