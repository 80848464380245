const messages = {
  aboutAbbyWorld: "About Abby's world",
  aboutAbbyWorldDescription:
    "Welcome to Abby's World!</br></br>Abby's World is an interactive children's program, based on the Compassion International curriculum for holistic development for children ages 3 to 9. Abby's world focuses on the spiritual, physical, socio-emotional and cognitive areas of development, presented by Wes who plays the role of tutor to his little friend Abby the bee. The two of them learn together about scriptures, Bible stories, and activities of daily life while having an amazing time.",
  aboutAbbyWorldDescription2:
    "Abby's World, has the purpose of educating and supporting children on issues such as family, self-love, their relationship with other people, the environment, hygiene, emotions, among others, all of them developed from the biblical worldview. yChrist centric that allows to stimulate the values, the thought and the physical well-being of the child according to his age.",
  aboutCompassion: "About Compassion",
  aboutCompassionDescription:
    "Compassion International is a child advocacy ministry that unites compassionate people with those living in poverty. Our mission is to free children from poverty in the name of Jesus. The goal is for each child to be a responsible, well-rounded, and fulfilled adult.",
  sponsorAChild: "Sponsor A Child",
};
export default messages;
