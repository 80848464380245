import axios from "axios";
import i18n from "../plugins/i18n";

axios.defaults.withCredentials = true;
const fetchClient = () => {
  const defaultOptions = {
    headers: {
      "Content-Type": "application/json",
      locale: i18n.locale,
      Authorization: "Bearer " + process.env.VUE_APP_API_KEY,
      anonymousId: localStorage.getItem('anonymousId'),
    },
    baseURL: process.env.VUE_APP_BASE_API,
  };

  // Create instance
  const instance = axios.create(defaultOptions);
  return instance;
};

export default fetchClient();
