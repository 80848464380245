<template>
  <v-container fluid class="pa-0 home">
    <recent-videos></recent-videos>
    <characters colNum="6" num="2"></characters>
    <preview-activities></preview-activities>
  </v-container>
</template>

<script>
import recentVideos from "@/components/home/recentVideos.vue";
import characters from "@/components/home/characters.vue";
import previewActivities from "@/components/home/previewActivities.vue";

export default {
  name: "Home",
  components: {
    recentVideos,
    characters,
    previewActivities,
  }
};
</script>
