<template>
  <v-container fluid class="background-panal pb-lg-16">
    <v-container>
      <v-row>
        <v-col cols="12" lg="2" class="pt-lg-16">
          <search-filter></search-filter>
        </v-col>
        <v-col cols="12" lg="10" class="mt-n16">
          <drawings></drawings>
          <crafts class="mt-16"></crafts>
          <games></games>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import Crafts from "@/components/activities/crafts.vue";
import drawings from "@/components/activities/drawings.vue";
import searchFilter from "@/components/searchFilter.vue";
import Games from "../components/activities/games.vue";

export default {
  components: {
    drawings,
    Crafts,
    searchFilter,
    Games,
  },
};
</script>
